import { inject, InjectionKey } from 'vue'
import { Karte as KarteModel } from '@/models'
import { Karte, KarteNumber, ModelKarteFilterInput } from '@/API'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import {
  updateKarte as updateKarteMutation,
  createKarteNumber,
  updateKarteNumber
} from '@/graphql/mutations'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { v4 as uuid } from 'uuid'
import { getKarte, getKarteNumber } from '@/graphql/queries'
import { SimpleKarte } from '@/composables/karte/types'
import { listSimpleKarte } from '@/composables/karte/queries'
import dayjs from 'dayjs'

interface GetKarteNumbersResult {
  data?: {
    getKarteNumber: KarteNumber
  }
}

interface GetKarteResult {
  data?: {
    getKarte: Karte
  }
}

interface ListKarteOrderResult {
  data?: {
    listKartes: {
      items: SimpleKarte[]
    }
  }
}

export const useKarteMutation = () => {
  const { clinicId } = inject(UseClinicKey) as UseClinicType

  // 新規保存時はオフラインでも保存できるようにDataStoreに保存する
  // ただしカルテ番号とカルテ順はサーバに問い合わせる。エラーになった場合、それぞれ不明なまま登録する。
  // 更新保存の際にカルテ番号とカルテ順は再度問い合わせる
  const registerKarte = async ({ karte }: {
    karte: KarteModel
  }) => {
    try {
      const newKarte = new KarteModel({
        ...JSON.parse(JSON.stringify(karte)), // Proxy化されているObjectを元に戻す
        id: karte.id === '' ? uuid() : karte.id,
        karteNumber: await _nextKarteNumber(),
        displayOrder: await _nextDisplayOrder(karte.patientId, karte.karteDate)
      })
      await DataStore.save<KarteModel>(newKarte)
      return newKarte
    } catch (e) {
      console.log(e)
      return Promise.reject(new Error('カルテの登録に失敗しました'))
    }
  }

  const updateKarte = async ({ karte } : {
    karte: KarteModel
  }) => {
    try {
      const storeData = await _getCurrentKarteFromStore(karte.id)
      if (storeData) {
        let karteNumber = storeData.karteNumber
        if (karteNumber === '') {
          karteNumber = await _nextKarteNumber()
        }

        let displayOrder = storeData.displayOrder
        if (displayOrder % 86400 === 36399) {
          displayOrder = await _nextDisplayOrder(karte.patientId!, karte.karteDate!)
        }

        await DataStore.save<KarteModel>(
          KarteModel.copyOf(storeData, updated => {
            updated.karteNumber = karteNumber
            updated.karteDate = karte.karteDate
            updated.displayOrder = displayOrder
            updated.title = karte.title
            updated.karteResults = [...JSON.parse(JSON.stringify(karte.karteResults))]
          })
        )
        return {
          ...storeData,
          karteNumber,
          displayOrder
        }
      } else {
        // データはDataStoreには無いので、サーバのデータを更新する
        const currentData = await _gerCurrentKarte(karte.id)
        if (!currentData) {
          return Promise.reject(new Error('サーバのカルテデータの取得に失敗しました'))
        }

        let karteNumber = currentData.karteNumber
        if (karteNumber === '') {
          karteNumber = await _nextKarteNumber()
        }

        let displayOrder = currentData.displayOrder
        if (displayOrder % 86400 === 36399) {
          displayOrder = await _nextDisplayOrder(karte.patientId!, karte.karteDate!)
        }

        const input = {
          ...karte,
          accountId: currentData.accountId,
          accountName: currentData.accountName,
          karteNumber: karteNumber,
          displayOrder: displayOrder,
          _version: currentData._version
        }
        await API.graphql(graphqlOperation(updateKarteMutation, { input }))
        return input
      }
    } catch (e) {
      console.log(e)
      return Promise.reject(new Error('カルテの更新に失敗しました'))
    }
  }

  const deleteKarte = async (id: string) => {
    try {
      // TODO: DataStoreの取得範囲にない古いカルテの場合にも対応する
      const original = await DataStore.query(KarteModel, k => k.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('カルテの削除に失敗しました'))
      }
      await DataStore.delete<KarteModel>(original[0])
    } catch (e) {
      console.log(e)
      return Promise.reject(new Error('カルテの削除に失敗しました'))
    }
  }

  const _nextKarteNumber = async () => {
    try {
      const res = await API.graphql(graphqlOperation(getKarteNumber, { id: clinicId.value })) as GetKarteNumbersResult
      if (res.data?.getKarteNumber !== null) {
        const param = {
          id: clinicId.value,
          karteNumber: res.data!.getKarteNumber.karteNumber + 1,
          _version: res.data!.getKarteNumber._version
        }
        await API.graphql(graphqlOperation(updateKarteNumber, { input: param }))
        return ('0000000' + (res.data!.getKarteNumber.karteNumber + 1)).substr(-8)
      } else {
        const param = {
          id: clinicId.value,
          karteNumber: 1
        }
        await API.graphql(graphqlOperation(createKarteNumber, { input: param }))
        return '00000001'
      }
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  const _nextDisplayOrder = async (patientId: string, karteDate: string) => {
    try {
      const filter: ModelKarteFilterInput = {
        patientId: { eq: patientId },
        karteDate: { eq: karteDate }
      }
      // TODO: 同日に1回で取得できない数のカルテがあった場合は、nextTokenでループする必要がある
      const res = await API.graphql(graphqlOperation(listSimpleKarte, { filter })) as ListKarteOrderResult
      if (res.data?.listKartes.items && res.data!.listKartes.items.length > 0) {
        const kartes = [...res.data!.listKartes.items]
        kartes.sort((a, b) => b.displayOrder - a.displayOrder)
        return kartes[0].displayOrder + 1
      } else {
        return dayjs(karteDate, 'YYYY-MM-DD').unix()
      }
    } catch (e) {
      console.log(e)

      // 表示順不明の場合は、その日の最後の秒を仮で設定する
      return dayjs(karteDate, 'YYYY-MM-DD').unix() + 86399
    }
  }

  const _getCurrentKarteFromStore = async (id: string): Promise<KarteModel|undefined> => {
    try {
      const original = await DataStore.query(KarteModel, k => k.id('eq', id))
      if (original.length === 0) {
        return undefined
      }
      return original[0]
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _gerCurrentKarte = async (id: string): Promise<Karte|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getKarte, { id })) as GetKarteResult
      return res.data?.getKarte
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerKarte,
    updateKarte,
    deleteKarte
  }
}

export type UseKarteMutationType = ReturnType<typeof useKarteMutation>
export const UseKarteMutationKey: InjectionKey<UseKarteMutationType> = Symbol('KarteMutation')
