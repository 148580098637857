
import {
  defineComponent,
  inject
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import { UseInquiryItemKey, UseInquiryItemType } from '@/composables/karteData/useInquiryItem'
import NormalEditBox from '@/components/molecules/editbox/normalEditBox.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import InquiryPageItem from '@/views/karteData/inquiry/InquiryPageItem.vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

export default defineComponent({
  name: 'InquiryPageItemForm',
  components: {
    InquiryPageItem,
    NormalButton,
    VueHeadButtonFrame,
    VueYScrollArea,
    NormalEditBox,
    VueContent
  },
  setup () {
    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      titles,
      pageIds,
      selectedInquiryItemIndex,
      addInquiryPage
    } = inject(UseInquiryItemKey) as UseInquiryItemType

    return {
      dataWrite,
      titles,
      pageIds,
      selectedInquiryItemIndex,
      addInquiryPage
    }
  }
})
