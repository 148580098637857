<template>
  <div class="group_data" v-cloak>
    <div class="group_title">
      <label>{{ previewData.title }}</label>
    </div>
    <div class="group_item">
      <template v-for="(result) in previewData.kartePartsResult">
        <karte-item-preview-time-range
          v-if="result.partsName==='timeRange'"
          :key="result.id"
          :result="result"></karte-item-preview-time-range>
        <karte-item-preview-single-line
          v-if="result.partsName==='singleLine'"
          :key="result.id"
          :result="result"></karte-item-preview-single-line>
        <karte-item-preview-multi-line
          v-if="result.partsName==='multiLine'"
          :key="result.id"
          :result="result"></karte-item-preview-multi-line>
        <karte-item-preview-images
          v-if="result.partsName==='images'"
          :key="result.id"
          :result="result"></karte-item-preview-images>
        <karte-item-preview-image-and-text
          v-if="result.partsName==='imageAndText'"
          :key="result.id"
          :result="result"></karte-item-preview-image-and-text>
        <karte-item-preview-attach-files
          v-if="result.partsName==='attachFiles'"
          :key="result.id"
          :result="result"></karte-item-preview-attach-files>
        <karte-item-preview-single-choice
          v-if="result.partsName==='singleChoice'"
          :key="result.id"
          :result="result"></karte-item-preview-single-choice>
        <karte-item-preview-multi-choice
          v-if="result.partsName==='multiChoice'"
          :key="result.id"
          :result="result"></karte-item-preview-multi-choice>
        <karte-item-preview-acu
          v-if="result.partsName==='acu'"
          :key="result.id"
          :result="result"></karte-item-preview-acu>
      </template>
    </div>
  </div>
</template>

<script>

import KarteItemPreviewTimeRange from '@/components/organisms/preview/karteItemPreviewTimeRange.vue'
import KarteItemPreviewSingleLine from '@/components/organisms/preview/karteItemPreviewSingleLine.vue'
import KarteItemPreviewMultiLine from '@/components/organisms/preview/karteItemPreviewMultiLine.vue'
import KarteItemPreviewImages from '@/components/organisms/preview/karteItemPreviewImages.vue'
import KarteItemPreviewImageAndText from '@/components/organisms/preview/karteItemPreviewImageAndText.vue'
import KarteItemPreviewAttachFiles from '@/components/organisms/preview/karteItemPreviewAttachFiles.vue'
import KarteItemPreviewSingleChoice from '@/components/organisms/preview/karteItemPreviewSingleChoice.vue'
import KarteItemPreviewMultiChoice from '@/components/organisms/preview/karteItemPreviewMultiChoice.vue'
import KarteItemPreviewAcu from '@/components/organisms/preview/karteItemPreviewAcu.vue'
export default {
  name: 'karte-item-preview',
  components: {
    KarteItemPreviewAcu,
    KarteItemPreviewMultiChoice,
    KarteItemPreviewSingleChoice,
    KarteItemPreviewAttachFiles,
    KarteItemPreviewImageAndText,
    KarteItemPreviewImages,
    KarteItemPreviewMultiLine,
    KarteItemPreviewSingleLine,
    KarteItemPreviewTimeRange
  },
  props: {
    previewData: Object
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.group_data {
  border: 1px solid #000000;
  border-bottom: 0;
  width: 200mm;
  display: flex;
}

.group_data:last-child {
  border-bottom: 1px solid #000000;
}

.group_title {
  border-right: 1px solid #000000;
  display: flex;
  padding: 2mm 1mm 2mm 1mm;
  width: 6mm;
  writing-mode: vertical-rl;
}

.group_item {
  font-size: 0;
  width: 200mm - 8mm;
}
</style>
