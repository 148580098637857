<template>
  <div class="carousel-frame">
    <div class="carousel-scroll-area">
      <slot></slot>
    </div>
    <carousel-arrow-button
      class="carousel-button-left"
      arrow="left"
      @click="onPrev"></carousel-arrow-button>
    <carousel-arrow-button
      class="carousel-button-right"
      arrow="right"
      @click="onNext"></carousel-arrow-button>
    <div class="carousel-dots-frame">
      <div class="carousel-dots">
        <carousel-dot
          v-for="(item, i) in slides"
          :key="'dot'+i"
          :selected="i===page"
          @click="onPage(i)"></carousel-dot>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselArrowButton from '@/components/atoms/carousel/carouselArrowButton.vue'
import CarouselDot from '@/components/atoms/carousel/carouselDot.vue'

export default {
  name: 'carousel',
  components: {
    CarouselDot,
    CarouselArrowButton
  },
  props: {},
  data () {
    return {
      page: 0,
      slides: []
    }
  },
  methods: {
    onPage (page) {
      this.page = page
      this.position(true)
    },
    onPrev () {
      this.page--
      if (this.page < 0) {
        this.page = this.slides.length - 1
      }
      this.position()
    },
    onNext () {
      this.page++
      if (this.page > this.slides.length - 1) {
        this.page = 0
      }
      this.position()
    },
    position (noTransition) {
      this.slides.forEach((s, i) => {
        let style
        switch ((i - this.page) < 0 ? (i - this.page) + this.slides.length : (i - this.page)) {
          case 0:
            style = 'display: show; left: 33.33333%;'
            break
          case 1:
            style = 'display: show; left: 66.66667%;'
            break
          case this.slides.length - 1:
            style = 'display: show; left: 0;'
            break
          default:
            style = 'display:hidden;'
            break
        }
        if (noTransition) {
          style += ' transition: none;'
        }
        s.style = style
      })
    }
  },
  computed: {},
  mounted () {
    this.slides = Array.from(document.getElementsByClassName('carousel-item-frame'))
    this.position()
  }
}
</script>

<style lang="scss" scoped>
.carousel-frame {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-scroll-area {
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
}

.carousel-button-left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-button-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-dots-frame {
  position: absolute;
  width: 100%;
  height: $carousel_dot_size;
  bottom: 10px;

  .carousel-dots {
    display: grid;
    grid-template-columns: repeat(auto-fit, $carousel_dot_size);
    justify-content: center;
    grid-gap: 10px;
  }
}
</style>
