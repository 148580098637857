<template>
  <div class="flex_frame">
    <label
      v-if="result.title"
      class="group_data_text_label">{{ result.title }}</label>
    <div class="options-frame">
      <div
        class="vue-flex-row option"
        v-for="(option, i) in result.options"
        :key="'options'+i">
        <template v-if="result.choices.includes(option)">
          <fa-icon class="icon" :icon="['fas', 'check-square']"></fa-icon>
          <div>{{ option }}</div>
        </template>
        <template v-else>
          <fa-icon class="icon" :icon="['far', 'square']"></fa-icon>
          <div>{{ option }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'karte-item-preview-multi-choice',
  props: {
    result: Object
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.flex_frame {
  display: flex;
}

.group_data_text_label {
  display: inline-block;
  font-size: 4mm;
  height: 6mm;
  line-height: 6mm;
  padding-left: 2mm;
  width: 30mm;
}

.options-frame {
  display: grid;
  grid-template-columns: repeat(auto-fit, 35mm);
  grid-column-gap: 5mm;
  width: 200mm - 8mm - 32mm - 3px;
  margin-top: 4mm;

  .option {
    font-size: 4mm;
    line-height: 6mm;
    padding: 2mm;
  }

  .icon {
    position: relative;
    font-size: 6mm;
    margin-right: 1mm;
  }
}
</style>
