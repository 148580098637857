<template>
  <div class="flex_frame">
    <template v-if="result.title">
      <label class="group_data_text_label">{{ result.title }}</label>
      <pre class="group_data_text_value">{{ result.text }}</pre>
    </template>
    <template v-else>
      <pre class="group_data_text_value">{{ result.text }}</pre>
    </template>
  </div>
</template>

<script>
export default {
  name: 'karte-item-preview-multi-line',
  props: {
    result: Object
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.flex_frame {
  display: flex;
}

.group_data_text_label {
  display: inline-block;
  font-size: 4mm;
  height: 6mm;
  line-height: 6mm;
  padding-left: 2mm;
  width: 20mm;
}

.group_data_text_value {
  font-size: 4mm;
  line-height: 6mm;
  margin: 0;
  padding: 2mm 10mm 2mm 10mm;
}
</style>
