
import {
  defineComponent,
  inject
} from 'vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'

export default defineComponent({
  name: 'karteDataMenu',
  components: {
    VueTitle,
    VueFrame,
    BackButton,
    NormalButton
  },
  setup () {
    const {
      clinicId,
      dataWrite,
      dataRead
    } = inject(UseClinicKey) as UseClinicType

    return {
      clinicId,
      dataWrite,
      dataRead
    }
  }
})
