
import {
  defineComponent
} from 'vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import KarteHeaderPreview from '@/components/organisms/preview/karteHeaderPreview.vue'
import KartePatientHeaderPreview from '@/components/organisms/preview/kartePatientHeaderPreview.vue'
import { useModal } from '@/composables/useModal'
import { usePreview } from '@/composables/karte/usePreview'
import KarteItemPreview from '@/components/organisms/preview/karteItemPreview.vue'

export default defineComponent({
  name: 'preview',
  components: {
    KarteItemPreview,
    KartePatientHeaderPreview,
    KarteHeaderPreview,
    VueYScrollArea,
    NormalButton,
    WhiteBackdrop
  },
  setup () {
    const {
      show,
      open,
      dismiss
    } = useModal()
    const {
      accountName,
      title,
      karteDateString,
      karteNumber,
      patient,
      previewData,
      setAccountName,
      setTitle,
      setKarteDate,
      setKarteNumber,
      setPatient,
      setPreviewData
    } = usePreview()

    return {
      show,
      open,
      dismiss,
      accountName,
      title,
      karteDateString,
      karteNumber,
      patient,
      previewData,
      setAccountName,
      setTitle,
      setKarteDate,
      setKarteNumber,
      setPatient,
      setPreviewData
    }
  }
})
