
import {
  computed,
  defineComponent,
  inject,
  ref
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import ListBox from '@/components/atoms/listBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import { UseKarteTemplateKey, UseKarteTemplateType } from '@/composables/karteData/useKarteTemplate'
import { VueMqType } from 'vue3-mq'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

export default defineComponent({
  name: 'karte-template-list',
  components: {
    Confirm,
    VueContent,
    NormalButton,
    ListBox
  },
  setup () {
    const mq = inject('mq') as VueMqType
    const confirmDeleteRef = ref<InstanceType<typeof Confirm>>()
    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      selectedKarteTemplateId,
      listBoxKarteTemplates,
      upTemplate,
      downTemplate,
      addTemplate,
      deleteTemplate,
      isFirst,
      isLast
    } = inject(UseKarteTemplateKey) as UseKarteTemplateType

    const onDelete = () => {
      confirmDeleteRef.value?.open()
    }

    const yesDelete = async () => {
      deleteTemplate()
    }

    const listSize = computed(() => {
      return ['mobile', 'tablet'].includes(mq.value) ? 8 : 10
    })

    return {
      confirmDeleteRef,
      dataWrite,
      listBoxKarteTemplates,
      selectedKarteTemplateId,
      upTemplate,
      downTemplate,
      addTemplate,
      onDelete,
      yesDelete,
      isFirst,
      isLast,
      listSize
    }
  }
})
