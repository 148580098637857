
import {
  defineComponent,
  inject, ref
} from 'vue'
import { useRouter } from 'vue-router'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { UseAccountClinicListKey, UseAccountClinicListType } from '@/composables/account/useAccountClinicList'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { RequestStatus } from '@/composables/commonTypes'
import ListTitle from '@/components/molecules/listTitle.vue'
import ListRow from '@/components/molecules/listRow.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import VueContent from '@/components/layout/vueContent.vue'

export default defineComponent({
  name: 'account-list',
  components: {
    VueContent,
    Confirm,
    LoadingMessage,
    VueYScrollArea,
    ListTitle,
    ListRow
  },
  setup () {
    const router = useRouter()
    const { append } = inject(UseErrorKey) as UseErrorType
    const { clinicId } = inject(UseClinicKey) as UseClinicType
    const {
      requestStatus,
      accountList,
      accountTitles,
      accountRows
    } = inject(UseAccountClinicListKey) as UseAccountClinicListType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType
    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const deleteId = ref<string>('')

    const onClick = ({ emitType, id }:{ emitType: string, id: string }) => {
      switch (emitType) {
        case 'change':
          router.push(`/w/${clinicId.value}/clinic_account_setting/${id}`)
          break
        case 'delete':
          deleteId.value = id
          confirmRef.value?.open()
          break
      }
    }

    // TODO: 予約の残っているアカウントは削除できないようにする。(他にも削除出来ない条件があるかも)
    const yes = async () => {
      try {
        await lockScreen()
        // await deleteAccountClinic(deleteId.value)
        await append('notify', 'アカウントを削除しました。')
      } catch (e) {
        console.log(e)
        await append('error', 'アカウントを削除しました。')
      } finally {
        await unlockScreen()
      }
    }

    return {
      RequestStatus,
      requestStatus,
      accountList,
      accountTitles,
      accountRows,
      confirmRef,
      onClick,
      yes
    }
  }
})
