
import {
  defineComponent,
  inject, ref
} from 'vue'
import { MaxAttachFileMega } from '@/config'
import IconButton from '@/components/atoms/button/iconButton.vue'
import DropArea from '@/components/molecules/dropArea.vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { DropAreaFileType } from '@/composables/types'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'
import FileNameText from '@/components/atoms/fileNameText.vue'
import { base64StringToBlob } from 'blob-util'

export default defineComponent({
  name: 'karte-parts-images',
  components: {
    FileNameText,
    DropArea,
    IconButton
  },
  props: {
    resultIndex: {
      type: Number,
      required: true
    },
    kartePartsIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const downloadRef = ref<InstanceType<typeof HTMLAnchorElement>>()
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      karteResult: {
        fileFileId,
        fileName
      },
      addKarteFile,
      deleteKarteFile
    } = inject(UseKarteKey) as UseKarteType
    const {
      fileMap
    } = inject(UseFileDBKey) as UseFileDBType

    const onFile = async (dropFile: DropAreaFileType) => {
      try {
        await addKarteFile(props.resultIndex, props.kartePartsIndex, dropFile)
      } catch (e) {
        await append('error', e.message)
      }
    }

    const onDropError = async (err: string) => {
      await append('error', err)
    }

    const onDeleteFile = async (index: number) => {
      try {
        await deleteKarteFile(props.resultIndex, props.kartePartsIndex, index)
      } catch (e) {
        await append('error', e.message)
      }
    }

    const onDownloadFile = (index: number) => {
      const fileId = fileFileId.value[props.resultIndex][props.kartePartsIndex][index]
      const file = fileMap.value[fileId]
      const blob = base64StringToBlob(file.base64.split(',')[1], file.fileType)
      const blobUrl = window.URL.createObjectURL(blob)

      const a = downloadRef.value!
      a.href = blobUrl
      a.download = file.name
      a.click()
      window.URL.revokeObjectURL(blobUrl)
    }

    return {
      downloadRef,
      MaxAttachFileMega,
      fileFileId,
      fileName,
      fileMap,
      onFile,
      onDropError,
      onDeleteFile,
      onDownloadFile
    }
  }
})
