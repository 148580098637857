
import {
  computed,
  defineComponent,
  inject,
  ref
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import ListBox from '@/components/atoms/listBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import { VueMqType } from 'vue3-mq'
import { UseInquiryItemKey, UseInquiryItemType } from '@/composables/karteData/useInquiryItem'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

export default defineComponent({
  name: 'inquiry-item-list',
  components: { NormalButton, Confirm, ListBox, VueContent },
  setup () {
    const confirmDeleteRef = ref<InstanceType<typeof Confirm>>()
    const mq = inject('mq') as VueMqType
    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      selectedInquiryItemId,
      listBoxInquiryItems,
      addItem,
      deleteItem,
      upItem,
      downItem,
      isFirst,
      isLast
    } = inject(UseInquiryItemKey) as UseInquiryItemType

    const onDelete = () => {
      confirmDeleteRef.value?.open()
    }

    const yesDelete = async () => {
      deleteItem()
    }

    const listSize = computed(() => {
      return ['mobile', 'tablet'].includes(mq.value) ? 8 : 10
    })

    return {
      confirmDeleteRef,
      dataWrite,
      selectedInquiryItemId,
      listBoxInquiryItems,
      addItem,
      deleteItem,
      upItem,
      downItem,
      onDelete,
      yesDelete,
      isFirst,
      isLast,
      listSize
    }
  }
})
