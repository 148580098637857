
import {
  defineComponent,
  inject
} from 'vue'
import MultiLineEditBox from '@/components/molecules/editbox/multiLineEditBox.vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'

export default defineComponent({
  name: 'karte-parts-multi-line',
  components: {
    MultiLineEditBox
  },
  props: {
    resultIndex: {
      type: Number,
      required: true
    },
    kartePartsIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const {
      karteResult: {
        resultTitle,
        text
      }
    } = inject(UseKarteKey) as UseKarteType

    return {
      resultTitle,
      text
    }
  }
})
