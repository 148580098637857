
import {
  defineComponent, inject
} from 'vue'

import LabelText from '@/components/atoms/labelText.vue'
import RubyText from '@/components/atoms/rubyText.vue'
import { UseKartePatientKey, UseKartePatientType } from '@/composables/karte/useKartePatient'

export default defineComponent({
  name: 'patient-header',
  components: {
    RubyText,
    LabelText
  },
  setup () {
    const {
      patientDetail
    } = inject(UseKartePatientKey) as UseKartePatientType

    return {
      patientDetail
    }
  }
})
