import { v4 as uuid } from 'uuid'
import { computed, inject, InjectionKey, reactive, ref, toRefs } from 'vue'
import {
  UseKarteItemSubscriptionKey,
  UseKarteItemSubscriptionType
} from '@/composables/karteData/useKarteItemSubscription'
import { CreateKarteItemInput, UpdateKarteItemInput, PartsName, KartePartsInput } from '@/API'
import { PartsName as PartsNameModel, BodyParts, Direction, KarteResult } from '@/models'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

type KarteItemsType = {
  titles: string[]
  partsIds: string[][]
  partsTitles: (string | undefined)[][]
  partsNames: string[][]
  options: (string[] | null)[][]
}
type KarteItemOrder = {
  id: string
  displayOrder: number
  arrayIndex: number
}

export const useKarteItem = () => {
  const karteItems = reactive<KarteItemsType>({
    titles: [],
    partsIds: [],
    partsTitles: [],
    partsNames: [],
    options: []
  })
  const order = ref<KarteItemOrder[]>([])
  const selectedKarteItemId = ref<string>('')

  const { dataWrite } = inject(UseClinicKey) as UseClinicType
  const { karteItems: originalKarteItems } = inject(UseKarteItemSubscriptionKey) as UseKarteItemSubscriptionType

  const kartePartsTitles = [
    {
      name: '順番',
      width: 1
    },
    {
      name: '項目部品名',
      width: 3
    },
    {
      name: '項目名',
      width: 3
    },
    {
      name: '選択肢',
      width: 3
    },
    {
      name: '操作',
      width: 3
    }
  ]

  const selectItems = [
    { value: 'timeRange', name: '時刻' },
    { value: 'singleLine', name: '1行テキスト' },
    { value: 'multiLine', name: '複数行テキスト' },
    { value: 'images', name: '画像リスト' },
    { value: 'imageAndText', name: '画像とテキスト' },
    { value: 'attachFiles', name: '添付ファイル' },
    { value: 'singleChoice', name: '単一選択' },
    { value: 'multiChoice', name: '複数選択' },
    { value: 'acu', name: '経穴とテキスト' }
  ]

  const initKarteItems = () => {
    const sorted = originalKarteItems.value
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder)

    karteItems.titles = sorted.map(k => k.title)
    karteItems.partsIds = sorted.map(k => k.karteParts.map(p => p.id))
    karteItems.partsTitles = sorted.map(k => k.karteParts.map(p => p.title))
    karteItems.partsNames = sorted.map(k => k.karteParts.map(p => p.partsName))
    karteItems.options = sorted.map(k => k.karteParts.map(p => p.options || null))

    order.value = [...sorted.map((s, i) => {
      return {
        id: s.id,
        displayOrder: s.displayOrder,
        arrayIndex: i
      }
    })]
  }

  const upItem = () => {
    if (order.value.length === 0 || selectedKarteItemId.value === '') {
      return
    }
    const targetIndex = order.value.findIndex(o => o.id === selectedKarteItemId.value)
    const newOrder = [...order.value]
    const tmp = {
      ...newOrder[targetIndex - 1],
      displayOrder: newOrder[targetIndex].displayOrder
    }
    newOrder.splice(targetIndex - 1, 1, {
      ...newOrder[targetIndex],
      displayOrder: newOrder[targetIndex - 1].displayOrder
    })
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const downItem = () => {
    if (order.value.length === 0 || selectedKarteItemId.value === '') {
      return
    }
    const targetIndex = order.value.findIndex(o => o.id === selectedKarteItemId.value)
    const newOrder = [...order.value]
    const tmp = {
      ...newOrder[targetIndex + 1],
      displayOrder: newOrder[targetIndex].displayOrder
    }
    newOrder.splice(targetIndex + 1, 1, {
      ...newOrder[targetIndex],
      displayOrder: newOrder[targetIndex + 1].displayOrder
    })
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const addItem = () => {
    karteItems.titles = [...karteItems.titles, '']
    karteItems.partsIds = [...karteItems.partsIds, []]
    karteItems.partsTitles = [...karteItems.partsTitles, []]
    karteItems.partsNames = [...karteItems.partsNames, []]
    karteItems.options = [...karteItems.options, []]

    const newId = uuid()
    selectedKarteItemId.value = newId
    order.value = [
      ...order.value,
      {
        id: newId,
        displayOrder: order.value.length === 0
          ? 1
          : order.value[order.value.length - 1].displayOrder + 1,
        arrayIndex: karteItems.titles.length - 1
      }
    ]
  }

  const deleteItem = () => {
    order.value = [...order.value.filter(o => o.id !== selectedKarteItemId.value)]
    selectedKarteItemId.value = ''
  }

  const upKarteParts = (index: number) => {
    if (selectedKarteItemIndex.value === -1 || karteItems.partsTitles.length === 0) {
      return
    }
    (Object.keys(karteItems) as (keyof KarteItemsType)[]).forEach(attr => {
      if (attr === 'titles') {
        return
      }
      const newItems = [...karteItems[attr][selectedKarteItemIndex.value]]
      const tmp = newItems[index - 1]
      newItems.splice(index - 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      karteItems[attr][selectedKarteItemIndex.value] = newItems as (string[] & string[][] & (string | undefined)[][] & (string[] | null)[][])
    })
  }

  const downKarteParts = (index: number) => {
    if (selectedKarteItemIndex.value === -1 || karteItems.partsTitles.length === 0) {
      return
    }
    (Object.keys(karteItems) as (keyof KarteItemsType)[]).forEach(attr => {
      if (attr === 'titles') {
        return
      }
      const newItems = [...karteItems[attr][selectedKarteItemIndex.value]]
      const tmp = newItems[index + 1]
      newItems.splice(index + 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      karteItems[attr][selectedKarteItemIndex.value] = newItems as (string[] & string[][] & (string | undefined)[][] & (string[] | null)[][])
    })
  }

  const addKarteParts = () => {
    if (selectedKarteItemIndex.value === -1) {
      return
    }
    karteItems.partsIds[selectedKarteItemIndex.value] = [...karteItems.partsIds[selectedKarteItemIndex.value], uuid()]
    karteItems.partsTitles[selectedKarteItemIndex.value] = [...karteItems.partsTitles[selectedKarteItemIndex.value], '']
    karteItems.partsNames[selectedKarteItemIndex.value] = [...karteItems.partsNames[selectedKarteItemIndex.value], 'singleLine']
    karteItems.options[selectedKarteItemIndex.value] = [...karteItems.options[selectedKarteItemIndex.value], null]
  }

  const deleteKarteParts = (index: number) => {
    if (selectedKarteItemIndex.value === -1 || karteItems.partsTitles.length === 0) {
      return
    }
    karteItems.partsIds[selectedKarteItemIndex.value] = karteItems.partsIds[selectedKarteItemIndex.value]
      .filter((p, j) => j !== index)
    karteItems.partsTitles[selectedKarteItemIndex.value] = karteItems.partsTitles[selectedKarteItemIndex.value]
      .filter((p, j) => j !== index)
    karteItems.partsNames[selectedKarteItemIndex.value] = karteItems.partsNames[selectedKarteItemIndex.value]
      .filter((p, j) => j !== index)
    karteItems.options[selectedKarteItemIndex.value] = karteItems.options[selectedKarteItemIndex.value]
      .filter((p, j) => j !== index)
  }

  const selectedKarteItemIndex = computed(() => {
    const selected = order.value.find(o => o.id === selectedKarteItemId.value)
    return selected ? selected.arrayIndex : -1
  })

  const listBoxKarteItems = computed(() => {
    return order.value
      .map(o => {
        return {
          value: o.id,
          name: karteItems.titles[o.arrayIndex] === ''
            ? '(タイトルなし)'
            : karteItems.titles[o.arrayIndex]
        }
      })
  })

  const kartePartsRow = computed(() => {
    return karteItems.partsTitles[selectedKarteItemIndex.value].map((title, j) => {
      const partsId = karteItems.partsIds[selectedKarteItemIndex.value][j]
      const partsName = karteItems.partsNames[selectedKarteItemIndex.value][j]
      const options = karteItems.options[selectedKarteItemIndex.value][j]

      const titleColumn = ['timeRange', 'singleLine', 'multiLine', 'singleChoice', 'multiChoice'].includes(partsName)
        ? {
          value: title,
          width: 3,
          type: 'edit',
          readonly: !dataWrite.value,
          emitType: 'title'
        }
        : {
          value: '',
          width: 3,
          type: 'text'
        }

      const optionColumn = ['singleChoice', 'multiChoice'].includes(partsName)
        ? !options || options.length === 0
          ? {
            button: options,
            width: 3,
            state: 'normal',
            value: '選択肢編集',
            disabled: !dataWrite.value,
            type: 'button',
            emitType: 'option'
          }
          : {
            values: options,
            width: 3,
            type: 'textMultiline',
            emitType: 'option'
          }
        : {
          value: '',
          width: 3,
          type: 'text'
        }

      return {
        id: partsId,
        columns: [
          {
            value: `${j + 1}`,
            width: 1,
            type: 'text'
          },
          {
            items: selectItems,
            selected: partsName,
            disabled: !dataWrite.value,
            width: 3,
            type: 'select',
            emitType: 'partsName'
          },
          titleColumn,
          optionColumn,
          {
            buttons: [
              {
                icon: 'arrow-up',
                state: 'normal',
                emitType: 'up',
                disabled: j === 0 || !dataWrite.value
              },
              {
                icon: 'arrow-down',
                state: 'normal',
                emitType: 'down',
                disabled: j === karteItems.partsTitles[selectedKarteItemIndex.value].length - 1 || !dataWrite.value
              },
              {
                icon: 'times',
                state: 'delete',
                emitType: 'delete',
                disabled: !dataWrite.value
              }
            ],
            width: 3,
            type: 'iconButtons'
          }
        ]
      }
    })
  })

  const previewData = computed<KarteResult[]>(() => {
    const title = karteItems.titles[selectedKarteItemIndex.value]
    const kartePartsResult = karteItems.partsIds[selectedKarteItemIndex.value].map((partsId, j) => {
      const partsTitles = karteItems.partsTitles[selectedKarteItemIndex.value][j]
      const partsName = karteItems.partsNames[selectedKarteItemIndex.value][j]
      const options = karteItems.options[selectedKarteItemIndex.value][j]

      return {
        id: partsId,
        partsName: partsName as PartsNameModel,
        title: partsTitles,
        startTime: '0900',
        endTime: '1100',
        text: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        files: [{
          id: uuid(),
          fileId: uuid(),
          name: 'sample.png',
          fileType: 'application/pdf',
          hash: 'dummy'
        }],
        options: options || [],
        choices: [],
        direction: Direction.FRONT,
        bodyParts: BodyParts.HEAD,
        parts: []
      }
    })

    return [
      {
        id: uuid(),
        karteItemId: selectedKarteItemId.value,
        title,
        kartePartsResult
      }
    ]
  })

  const isFirst = computed(() => {
    if (order.value.length === 0 || selectedKarteItemId.value === '') {
      return false
    }
    return order.value[0].id === selectedKarteItemId.value
  })

  const isLast = computed(() => {
    if (order.value.length === 0 || selectedKarteItemId.value === '') {
      return false
    }
    return order.value[order.value.length - 1].id === selectedKarteItemId.value
  })

  const addedKarteItems = computed<CreateKarteItemInput[]>(() => {
    return order.value
      .filter(o => originalKarteItems.value.find(org => org.id === o.id) === undefined)
      .map(o => {
        return {
          id: o.id,
          displayOrder: o.displayOrder,
          title: karteItems.titles[o.arrayIndex],
          karteParts: _karteParts(o.arrayIndex)
        }
      })
  })

  const updatedKarteItems = computed<UpdateKarteItemInput[]>(() => {
    return order.value
      .filter(o => {
        const org = originalKarteItems.value.find(org => org.id === o.id)
        if (!org) {
          return false
        }
        return o.displayOrder !== org.displayOrder ||
          karteItems.titles[o.arrayIndex] !== org.title ||
          org.karteParts.length !== karteItems.partsIds[o.arrayIndex].length ||
          org.karteParts.some((p, j) => {
            return karteItems.partsIds[o.arrayIndex][j] !== p.id ||
              karteItems.partsTitles[o.arrayIndex][j] !== p.title ||
              karteItems.partsNames[o.arrayIndex][j] !== p.partsName ||
              JSON.stringify(karteItems.options[o.arrayIndex][j]) !== JSON.stringify(p.options)
          })
      })
      .map(o => {
        return {
          id: o.id,
          displayOrder: o.displayOrder,
          title: karteItems.titles[o.arrayIndex],
          karteParts: _karteParts(o.arrayIndex)
        }
      })
  })

  const deletedKarteItemIds = computed(() => {
    return originalKarteItems.value
      .filter(org => order.value.find(o => o.id === org.id) === undefined)
      .map(org => org.id)
  })

  const editing = computed(() => {
    return (
      addedKarteItems.value.length > 0 ||
      updatedKarteItems.value.length > 0 ||
      deletedKarteItemIds.value.length > 0
    )
  })

  const _karteParts = (arrayIndex: number):KartePartsInput[] => {
    return karteItems.partsTitles[arrayIndex].map((title, j) => {
      return {
        id: karteItems.partsIds[arrayIndex][j],
        title: karteItems.partsTitles[arrayIndex][j],
        partsName: karteItems.partsNames[arrayIndex][j] as PartsName,
        options: karteItems.options[arrayIndex][j] || undefined
      }
    })
  }

  return {
    kartePartsTitles,
    ...toRefs(karteItems),
    selectedKarteItemId,
    initKarteItems,
    upItem,
    downItem,
    addItem,
    deleteItem,
    upKarteParts,
    downKarteParts,
    addKarteParts,
    deleteKarteParts,
    selectedKarteItemIndex,
    listBoxKarteItems,
    kartePartsRow,
    previewData,
    isFirst,
    isLast,
    addedKarteItems,
    updatedKarteItems,
    deletedKarteItemIds,
    editing,
    originalKarteItems
  }
}

export type UseKarteItemType = ReturnType<typeof useKarteItem>
export const UseKarteItemKey: InjectionKey<UseKarteItemType> = Symbol('KarteItem')
