
import { defineComponent, inject, ref } from 'vue'
import {
  UseAccountClinicKey,
  UseAccountClinicType
} from '@/composables/account/useAccountClinic'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import VueContent from '@/components/layout/vueContent.vue'
import AlertMessage from '@/components/atoms/alert/alertMessage.vue'
import RadioButtonGroup from '@/components/molecules/radioButtonGroup.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ToggleSwitch from '@/components/atoms/toggleSwitch.vue'
import Description from '@/components/atoms/Description.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'

export default defineComponent({
  name: 'account-setting-permissions',
  components: {
    VueContentItemFrame,
    Description,
    ToggleSwitch,
    NormalButton,
    RadioButtonGroup,
    AlertMessage,
    VueContent
  },
  setup () {
    const {
      myself,
      selectedPermissions,
      acceptAppointment,
      clinicOwner,
      radioButtonGroups,
      permissionError,
      updateAccount
    } = inject(UseAccountClinicKey) as UseAccountClinicType
    const { append } = inject(UseErrorKey) as UseErrorType
    const requesting = ref(false)

    const onSubmitPermissions = async () => {
      if (permissionError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await updateAccount()
        await append('notify', 'アクセス権を変更しました')
      } catch (e) {
        console.log(e)
        await append('error', 'アクセス権の変更に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    return {
      myself,
      selectedPermissions,
      acceptAppointment,
      clinicOwner,
      radioButtonGroups,
      permissionError,
      requesting,
      onSubmitPermissions
    }
  }
})
