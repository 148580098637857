
import {
  defineComponent,
  inject, ref
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import NormalEditBox from '@/components/molecules/editbox/normalEditBox.vue'
import ListTitle from '@/components/molecules/listTitle.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ListRow from '@/components/molecules/listRow.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import KarteOptionModal from '@/views/karteData/item/KarteOptionModal.vue'
import Preview from '@/components/organisms/preview/preview.vue'
import { UseKarteItemKey, UseKarteItemType } from '@/composables/karteData/useKarteItem'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

export default defineComponent({
  name: 'karte-parts-form',
  components: {
    Preview,
    KarteOptionModal,
    VueYScrollArea,
    ListRow,
    VueHeadButtonFrame,
    VueContent,
    NormalButton,
    ListTitle,
    NormalEditBox
  },
  setup () {
    const optionRef = ref<InstanceType<typeof KarteOptionModal>>()
    const previewRef = ref<InstanceType<typeof Preview>>()

    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      kartePartsTitles,
      titles,
      partsNames,
      partsTitles,
      kartePartsRow,
      previewData,
      selectedKarteItemIndex,
      upKarteParts,
      downKarteParts,
      addKarteParts,
      deleteKarteParts
    } = inject(UseKarteItemKey) as UseKarteItemType

    const onClick = (index: number, { emitType }: { emitType: string }) => {
      switch (emitType) {
        case 'up':
          upKarteParts(index)
          break
        case 'down':
          downKarteParts(index)
          break
        case 'delete':
          deleteKarteParts(index)
          break
        case 'option':
          if (dataWrite.value) {
            optionRef.value?.open(index)
          }
          break
      }
    }

    const onSelectPartsName = (index: number, value: string) => {
      partsNames.value[selectedKarteItemIndex.value][index] = value
    }

    const onInputPartsTitle = (index: number, value: string) => {
      partsTitles.value[selectedKarteItemIndex.value][index] = value
    }

    const onPreview = () => {
      previewRef.value?.setPreviewData(previewData.value)
      previewRef.value?.open()
    }

    return {
      optionRef,
      previewRef,
      dataWrite,
      kartePartsTitles,
      titles,
      kartePartsRow,
      selectedKarteItemIndex,
      onClick,
      onSelectPartsName,
      onInputPartsTitle,
      addKarteParts,
      onPreview
    }
  }
})
