
import { defineComponent, inject, ref } from 'vue'
import { useSubmit } from '@/composables/useSubmit'
import { useAccountInvitation } from '@/composables/account/useAccountInvitation'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueContent from '@/components/layout/vueContent.vue'
import ValidateEditBox from '@/components/molecules/editbox/validateEditBox.vue'
import RadioButtonGroup from '@/components/molecules/radioButtonGroup.vue'
import AlertMessage from '@/components/atoms/alert/alertMessage.vue'
import Description from '@/components/atoms/Description.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import ToggleSwitch from '@/components/atoms/toggleSwitch.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import Confirm from '@/components/organisms/Confirm.vue'

export default defineComponent({
  name: 'invite-account',
  components: {
    Confirm,
    NormalButton,
    ToggleSwitch,
    VueContentItemFrame,
    Description,
    AlertMessage,
    RadioButtonGroup,
    ValidateEditBox,
    VueContent,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const { submitted, submit } = useSubmit()
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const {
      email,
      emailError,
      acceptAppointment,
      selectedPermissions,
      radioButtonGroups,
      permissionError,
      createAccountInvitation
    } = useAccountInvitation()
    const { append } = inject(UseErrorKey) as UseErrorType
    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const requesting = ref(false)

    const onSubmit = () => {
      submit()
      if (emailError.value !== undefined || permissionError.value !== undefined) {
        return
      }
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        requesting.value = true
        await createAccountInvitation()
        await append('notify', 'アカウントを招待しました')
        await back()
      } catch (e) {
        console.log(e)
        await append('error', 'アカウントの招待に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    return {
      submitted,
      email,
      emailError,
      acceptAppointment,
      selectedPermissions,
      radioButtonGroups,
      permissionError,
      confirmRef,
      requesting,
      onSubmit,
      yes
    }
  }
})
