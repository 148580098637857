
import {
  defineComponent,
  inject,
  ref
} from 'vue'
import VueSubContent from '@/components/layout/vueSubContent.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import IconButtonMiddle from '@/components/atoms/button/iconButtonMiddle.vue'
import NormalEditBox from '@/components/molecules/editbox/normalEditBox.vue'
import ListTitle from '@/components/molecules/listTitle.vue'
import ListRow from '@/components/molecules/listRow.vue'
import InquiryOptionModal from '@/views/karteData/inquiry/InquiryOptionModal.vue'
import { UseInquiryItemKey, UseInquiryItemType } from '@/composables/karteData/useInquiryItem'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

export default defineComponent({
  name: 'inquiry-page-item',
  components: {
    InquiryOptionModal,
    ListRow,
    ListTitle,
    NormalEditBox,
    IconButtonMiddle,
    VueHeadButtonFrame,
    VueSubContent
  },
  props: {
    pageIndex: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const optionRef = ref<InstanceType<typeof InquiryOptionModal>>()

    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      inquiryPageItemTitles,
      pageTitles,
      pageItemIds,
      valueTypes,
      pageItemTitles,
      required,
      upInquiryPage,
      downInquiryPage,
      deleteInquiryPage,
      addInquiryPageItem,
      deleteInquiryPageItem,
      upInquiryPageItem,
      downInquiryPageItem,
      selectedInquiryItemIndex,
      inquiryPageItemRows
    } = inject(UseInquiryItemKey) as UseInquiryItemType

    const onClick = (itemIndex: number, { emitType }: { emitType: string }) => {
      switch (emitType) {
        case 'up':
          upInquiryPageItem(props.pageIndex, itemIndex)
          break
        case 'down':
          downInquiryPageItem(props.pageIndex, itemIndex)
          break
        case 'delete':
          deleteInquiryPageItem(props.pageIndex, itemIndex)
          break
        case 'option':
          if (dataWrite.value) {
            optionRef.value?.open(props.pageIndex, itemIndex)
          }
          break
      }
    }

    const onSelectValueType = (itemIndex: number, value: string) => {
      valueTypes.value[selectedInquiryItemIndex.value][props.pageIndex][itemIndex] = value
    }

    const onPageItemTitle = (itemIndex: number, value: string) => {
      pageItemTitles.value[selectedInquiryItemIndex.value][props.pageIndex][itemIndex] = value
    }

    const onRequired = (itemIndex: number) => {
      if (dataWrite.value) {
        required.value[selectedInquiryItemIndex.value][props.pageIndex][itemIndex] = !(required.value[selectedInquiryItemIndex.value][props.pageIndex][itemIndex])
      }
    }

    return {
      optionRef,
      dataWrite,
      inquiryPageItemTitles,
      pageTitles,
      pageItemIds,
      upInquiryPage,
      downInquiryPage,
      deleteInquiryPage,
      addInquiryPageItem,
      selectedInquiryItemIndex,
      inquiryPageItemRows,
      onClick,
      onSelectValueType,
      onPageItemTitle,
      onRequired
    }
  }
})
