
import {
  defineComponent,
  inject,
  onMounted,
  provide,
  ref
} from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import BodyPartsList from '@/views/karteData/acu/BodyPartsList.vue'
import AcuForm from '@/views/karteData/acu/AcuForm.vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { usePreventReload } from '@/composables/usePreventReload'
import { useAcuItem, UseAcuItemKey } from '@/composables/karteData/useAcuItem'
import { useAcuItemMutation } from '@/composables/karteData/useAcuIetmMutation'

export default defineComponent({
  name: 'KarteDataAcu',
  components: {
    AcuForm,
    BodyPartsList,
    VueContentRemain,
    VueButtonFrame,
    VueTwoColumnContent,
    VueTitle,
    VueFrame,
    Confirm,
    NormalButton
  },
  setup () {
    const confirmBackRef = ref<InstanceType<typeof Confirm>>()
    const confirmSaveRef = ref<InstanceType<typeof Confirm>>()
    const leaving = ref<boolean>(false)

    const acuItem = useAcuItem()
    const {
      initAcuItems,
      setIds,
      editing,
      addedAcuItems,
      updatedAcuItems
    } = acuItem
    provide(UseAcuItemKey, acuItem)
    const {
      registerAcuItem,
      updateAcuItem
    } = useAcuItemMutation()

    const { initialized, dataWrite } = inject(UseClinicKey) as UseClinicType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      lockScreen,
      unlockScreen
    } = inject(UseLockScreenKey) as UseLockScreenType

    const onBack = async () => {
      if (editing.value) {
        confirmBackRef.value?.open()
      } else {
        leaving.value = true
        await back()
      }
    }

    const yesBack = async () => {
      leaving.value = true
      await back()
    }

    const onSave = () => {
      confirmSaveRef.value?.open()
    }

    const yesSave = async () => {
      try {
        await lockScreen()
        await Promise.all(addedAcuItems.value.map(async acuItem => {
          return await registerAcuItem({ acuItem })
        }))
        setIds(addedAcuItems.value)
        await Promise.all(updatedAcuItems.value.map(async acuItem => {
          return await updateAcuItem({ acuItem })
        }))
        await append('notify', '経穴リストを保存しました')
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    }

    const preventReload = (event: BeforeUnloadEvent): void => {
      if (editing.value) {
        event.preventDefault()
        event.returnValue = '未保存の経穴リストがあります'
      }
    }
    usePreventReload(preventReload)

    onMounted(async () => {
      await lockScreen()
      await initialized.value
      initAcuItems()
      await unlockScreen()
    })

    onBeforeRouteLeave(async (to, from, next) => {
      if (leaving.value) {
        next()
      } else if (editing.value) {
        confirmBackRef.value?.open()
        next(false)
      } else {
        next()
      }
    })

    return {
      confirmBackRef,
      confirmSaveRef,
      dataWrite,
      onBack,
      yesBack,
      onSave,
      yesSave,
      addedAcuItems,
      updatedAcuItems,
      editing
    }
  }
})
