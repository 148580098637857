<template>
  <div class="body-frame">
    <div
      class="body-inner-frame"
      :style="offset"
      @click.stop="onClick">
      <img :src="bodyImage" class="body-image" alt="">
      <svg viewBox="0 0 350 680" class="body-svg">
        <path class="body-path" :d="acuParts.path"></path>
        <circle
          v-for="acu in acuList"
          :key="'circle-' + acu.id"
          :cx="acu.x"
          :cy="acu.y"
          :r="radius"
          class="acu"></circle>
      </svg>
    </div>
    <div class="body-parts_text">{{ acuParts.name }}</div>
    <div class="vue-flex-column acu-text-frame">
      <div
        v-for="acu in acuList"
        :key="'text-' + acu.id"
        class="acu-text">{{ acu.name }}
      </div>
    </div>
  </div>
</template>

<script>
import acuList from '@/assets/acupressureList'
import bodyBack from '@/assets/body_back.png'
import bodyFront from '@/assets/body_front.png'
import bodyLeft from '@/assets/body_left.png'
import bodyRight from '@/assets/body_right.png'

const bodyPngs = {
  'body_back.png': bodyBack,
  'body_front.png': bodyFront,
  'body_left.png': bodyLeft,
  'body_right.png': bodyRight
}

const frameSize = 60 * (96 / 2.54 / 10)
const partsRatio = 0.85
const radius = 4.0

export default {
  name: 'body-parts-image-print',
  components: {},
  props: {
    direction: {
      type: String,
      required: true,
      default: 'front',
      validator (value) {
        return ['front', 'left', 'back', 'right'].indexOf(value) !== -1
      }
    },
    partsId: {
      type: String,
      required: true,
      default: 'head',
      validator (value) {
        return [
          'head',
          'body',
          'back',
          'rightUpperArm',
          'rightLowerArm',
          'leftUpperArm',
          'leftLowerArm',
          'rightUpperLeg',
          'rightLowerLeg',
          'leftUpperLeg',
          'leftLowerLeg'
        ].indexOf(value) !== -1
      }
    },
    acuList: Array
  },
  methods: {
    onClick (event) {
      const ratio = (frameSize * partsRatio) / Math.max(this.acuParts.width, this.acuParts.height)
      const x = Math.ceil((event.layerX - (frameSize / 2)) / ratio) + this.acuParts.center[0]
      const y = Math.ceil((event.layerY - (frameSize / 2)) / ratio) + this.acuParts.center[1]
      this.$emit('click', {
        x,
        y
      })
    }
  },
  computed: {
    bodyImage () {
      return bodyPngs[acuList[this.direction].image]
    },
    acuParts () {
      if (Object.prototype.hasOwnProperty.call(acuList[this.direction].parts, this.partsId)) {
        return acuList[this.direction].parts[this.partsId]
      }

      return {
        name: 'N/A',
        path: ''
      }
    },
    offset () {
      const ratio = (frameSize * partsRatio) / Math.max(this.acuParts.width, this.acuParts.height)
      const left = (frameSize / 2) - this.acuParts.center[0] * ratio
      const top = (frameSize / 2) - this.acuParts.center[1] * ratio
      return `transform: translate(${left}px, ${top}px) scale(${ratio}); transform-origin: 0 0;`
    },
    radius () {
      const ratio = (frameSize * partsRatio) / Math.max(this.acuParts.width, this.acuParts.height)
      return radius / ratio
    }
  }
}
</script>

<style lang="scss" scoped>
$frame-size: 60mm;

.body-frame {
  position: relative;
  overflow: hidden;
  width: $frame-size;
  height: $frame-size;
  box-sizing: border-box;
  border-right: 1px solid #000000;

  .body-inner-frame {
    position: relative;
    width: 350px;
    height: 680px;
    cursor: crosshair;

    .body-image,
    .body-svg {
      position: absolute;
      height: 100%;
    }

    .body-svg {
      .body-path {
        fill: $base_color_gray;
        opacity: 0.7;
      }
    }

    .acu {
      fill: $base_color_gray_darker;
    }

    .acu-text {
      fill: #000000;
      font-size: 12px;
    }
  }

  .body-parts_text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 8px;
    font-family: sans-serif;
    font-size: $base_font_size;
  }

  .acu-text-frame {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 8px;

    .acu-text {
      font-family: sans-serif;
      font-weight: bold;
      font-size: $base_font_size * 0.9;
    }
  }
}
</style>
