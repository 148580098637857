
import {
  defineComponent, inject, onMounted, provide, ref
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueContent from '@/components/layout/vueContent.vue'
import KarteHeader from '@/views/karte/Karte/karteHeader.vue'
import VueSubContent from '@/components/layout/vueSubContent.vue'
import PatientHeader from '@/views/karte/Karte/patientHeader.vue'
import KarteTitle from '@/views/karte/Karte/karteTitle.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import KarteResult from '@/views/karte/Karte/karteResult.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import KarteItemModal from '@/views/karte/Karte/modal/karteItemModal.vue'
import Preview from '@/components/organisms/preview/preview.vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useKartePatient, UseKartePatientKey } from '@/composables/karte/useKartePatient'
import { useKarte, UseKarteKey } from '@/composables/karte/useKarte'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { onBeforeRouteLeave } from 'vue-router'
import { useKarteMutation } from '@/composables/karte/useKarteMutation'
import {
  UseKarteSubscriptionKey,
  UseKarteSubscriptionType
} from '@/composables/karte/useKarteSubscription'
import { useDelay } from '@/composables/useDelay'
import { useKarteItemModal, UseKarteItemModalKey } from '@/composables/karte/useKarteItemModel'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'

export default defineComponent({
  name: 'Karte',
  components: {
    Preview,
    KarteItemModal,
    VueYScrollArea,
    VueContentRemain,
    KarteResult,
    Confirm,
    VueButtonFrame,
    NormalButton,
    KarteTitle,
    PatientHeader,
    VueSubContent,
    KarteHeader,
    VueContent,
    VueFrame
  },
  setup () {
    const previewRef = ref<InstanceType<typeof Preview>>()
    const karteItemRef = ref<InstanceType<typeof KarteItemModal>>()
    const confirmBackRef = ref<InstanceType<typeof Confirm>>()
    const confirmDeleteRef = ref<InstanceType<typeof Confirm>>()
    const confirmSaveRef = ref<InstanceType<typeof Confirm>>()
    const leaving = ref<boolean>(false)

    const kartePatient = useKartePatient()
    provide(UseKartePatientKey, kartePatient)

    const karte = useKarte()
    const {
      karte: {
        id: karteId
      },
      order,
      saved,
      addResult,
      newKarte,
      newFileIds,
      updateFileIds,
      deleteFileIds,
      editing
    } = karte
    provide(UseKarteKey, karte)

    const karteItemModal = useKarteItemModal()
    const {
      clearSelect,
      selectedKarteItems
    } = karteItemModal
    provide(UseKarteItemModalKey, karteItemModal)

    const {
      addUploadQue,
      addDeleteQue,
      getFile,
      deleteFile
    } = inject(UseFileDBKey) as UseFileDBType

    const { patientWrite } = inject(UseClinicKey) as UseClinicType
    const { initialized } = inject(UseClinicKey) as UseClinicType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      lockScreen,
      unlockScreen
    } = inject(UseLockScreenKey) as UseLockScreenType
    const { fetchKarte } = inject(UseKarteSubscriptionKey) as UseKarteSubscriptionType

    // fixed要素のボタンを画面遷移アニメーション完了前に表示すると位置がおかしいので、
    // 少し送らせてから表示する。
    const { delayed } = useDelay(1000)

    const {
      registerKarte,
      updateKarte,
      deleteKarte
    } = useKarteMutation()

    const onPreview = () => {
      previewRef.value?.setAccountName(newKarte.value.accountName)
      previewRef.value?.setTitle(newKarte.value.title)
      previewRef.value?.setKarteDate(newKarte.value.karteDate)
      previewRef.value?.setKarteNumber(newKarte.value.karteNumber)
      previewRef.value?.setPatient(kartePatient.patientDetail.value!.patient)
      previewRef.value?.setPreviewData(newKarte.value.karteResults)
      previewRef.value?.open()
    }

    const onAddKarteItem = () => {
      clearSelect()
      karteItemRef.value?.open()
    }

    const yseKarteItem = async () => {
      if (!selectedKarteItems.value) {
        return
      }
      addResult(selectedKarteItems.value!)
    }

    const onBack = async () => {
      if (editing.value) {
        confirmBackRef.value?.open()
      } else {
        leaving.value = true
        await back()
      }
    }

    const yesBack = async () => {
      leaving.value = true
      await back()
    }

    const onDelete = () => {
      confirmDeleteRef.value?.open()
    }

    const yesDelete = async () => {
      try {
        await Promise.all(newFileIds.value.map(async fileId => {
          try {
            await deleteFile(fileId)
          } catch (e) {
            return Promise.reject(e)
          }
          return true
        }))
        await addDeleteQue(newFileIds.value)
        await deleteKarte(karteId.value)
        await append('notify', 'カルテを削除しました')
        leaving.value = true
        await back()
      } catch (e) {
        console.log(e)
        await append('error', 'カルテの削除に失敗しました')
      }
    }

    const onSave = () => {
      confirmSaveRef.value?.open()
    }

    const yesSave = async () => {
      try {
        await lockScreen()
        if (newKarte.value.id === '') {
          const res = await registerKarte({ karte: newKarte.value })
          await addUploadQue(updateFileIds.value)
          await addDeleteQue(deleteFileIds.value)
          saved(res.id, res.karteNumber, res.displayOrder)
        } else {
          const res = await updateKarte({ karte: newKarte.value })
          await addUploadQue(updateFileIds.value)
          await addDeleteQue(deleteFileIds.value)
          saved(res.id, res.karteNumber, res.displayOrder)
        }
        await fetchKarte()
        await append('notify', 'カルテを保存しました')
      } catch (e) {
        console.log(e)
        await append('error', 'カルテの保存に失敗しました')
        return Promise.reject(e)
      } finally {
        await unlockScreen()
      }
    }

    onMounted(async () => {
      try {
        await lockScreen()
        await initialized.value
        await Promise.all(newFileIds.value.map(async fileId => {
          return await getFile(fileId)
        }))
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    })

    onBeforeRouteLeave(async (to, from, next) => {
      if (leaving.value) {
        next()
      } else if (editing.value) {
        confirmBackRef.value?.open()
        next(false)
      } else {
        next()
      }
    })

    return {
      previewRef,
      karteItemRef,
      confirmBackRef,
      confirmDeleteRef,
      confirmSaveRef,
      patientWrite,
      onPreview,
      onAddKarteItem,
      yseKarteItem,
      onBack,
      yesBack,
      onDelete,
      yesDelete,
      onSave,
      yesSave,
      karteId,
      order,
      editing,
      delayed
    }
  }
})
