
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import mockImage from '@/assets/300x300.png'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'

export default defineComponent({
  name: 'karte-item-preview-image-and-text',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { fileMap } = inject(UseFileDBKey) as UseFileDBType

    const base64 = computed(() => {
      return props.result.files.length > 0
        ? props.result.files[0].hash === 'dummy'
          ? mockImage
          : fileMap.value[props.result.files[0].fileId].base64
        : ''
    })

    return {
      base64
    }
  }
})
