import { DirectiveBinding } from 'vue'
import { ComponentPublicInstance } from '@vue/runtime-core'

export const focusDirective = {
  mounted (el: HTMLElement, binding: DirectiveBinding): void {
    const target = binding.instance?.$refs[binding.value] as HTMLElement & ComponentPublicInstance
    // router-viewのトランジションが終わってからフォーカスしないとアニメーションが乱れる
    setTimeout(() => {
      target.focus()
    }, 500)
  }
}
