<template>
  <div class="patient_header">
    <div class="patient_header_id">
      <label>患者ID</label>
      {{ patient.patientNumber }}
    </div>
    <div class="patient_header_name">
      <label>名前</label>
      <div class="patient_header_name_name">{{ patient.name }}</div>
      <div class="patient_header_name_read">{{ patient.read }}</div>
    </div>
    <div class="patient_header_gender">
      <label>性別</label>
      <span class="vue-padding-item">{{ patientGender }}</span>
    </div>
    <div class="patient_header_birthdate">
      <label>生年月日</label>
      {{ formatBirthDate }}
      <span class="vue-padding-item">{{ patientAge }}歳</span>
    </div>
  </div>
</template>

<script>
import { formatYMD, age } from '@/plugins/functions'
import { genderString } from '@/composables/patient/types'

export default {
  name: 'karte-patient-header-preview',
  props: {
    patient: Object
  },
  computed: {
    patientGender () {
      return genderString(this.patient.gender)
    },
    formatBirthDate () {
      return formatYMD(this.patient.birthDate)
    },
    patientAge () {
      return age(this.patient.birthDate)
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.patient_header {
  height: 12mm;
  position: relative;
  width: 200mm;
}

.patient_header_id {
  border: 1px solid #000000;
  border-right: 0;
  border-bottom: 0;
  position: absolute;
  height: 12mm;
  line-height: 12mm;
  left: 0;
  padding-left: 2mm;
  top: 0;
  width: 40mm;
}

.patient_header_name {
  border: 1px solid #000000;
  border-right: 0;
  border-bottom: 0;
  position: absolute;
  height: 12mm;
  line-height: 12mm;
  left: 40mm;
  padding-left: 2mm;
  top: 0;
  width: 60mm;
}

.patient_header_name_name {
  font-family: serif;
  font-size: 6mm;
  left: 15mm;
  letter-spacing: 2mm;
  position: absolute;
  top: 2mm;
}

.patient_header_name_read {
  font-family: serif;
  font-size: 3mm;
  left: 15mm;
  letter-spacing: 2mm;
  position: absolute;
  top: -3mm;
}

.patient_header_gender {
  border: 1px solid #000000;
  border-right: 0;
  border-bottom: 0;
  position: absolute;
  height: 12mm;
  line-height: 12mm;
  left: 100mm;
  padding-left: 4mm;
  top: 0;
  width: 30mm;
}

.patient_header_birthdate {
  border: 1px solid #000000;
  border-bottom: 0;
  position: absolute;
  height: 12mm;
  line-height: 12mm;
  left: 130mm;
  padding-left: 2mm;
  top: 0;
  width: 68mm;
}
</style>
