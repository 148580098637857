<template>
  <div class="flex_frame">
    <template v-if="result.title">
      <label class="group_data_head_label">{{ result.title }}</label>
      <pre class="group_data_head_value">{{ result.text }}</pre>
    </template>
    <template v-else>
      <pre class="group_data_head_value_only">{{ result.text }}</pre>
    </template>
  </div>
</template>

<script>
export default {
  name: 'karte-item-preview-single-line',
  props: {
    result: Object
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.flex_frame {
  display: flex;
}

.group_data_head_label {
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  display: inline-block;
  font-size: 4mm;
  height: 6mm;
  line-height: 6mm;
  padding-left: 2mm;
  width: 20mm;
}

.group_data_head_value {
  border-bottom: 1px solid #000000;
  display: inline-block;
  font-size: 4mm;
  height: 6mm;
  line-height: 6mm;
  padding-left: 2mm;
  width: 200mm - 8mm - 22mm - 2mm - 3px;
}

.group_data_head_value_only {
  border-bottom: 1px solid #000000;
  display: inline-block;
  font-size: 4mm;
  height: 6mm;
  line-height: 6mm;
  padding-left: 2mm;
  width: 200mm - 8mm - 3px;
}
</style>
