
import {
  defineComponent, inject
} from 'vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ListBox from '@/components/atoms/listBox.vue'
import { useModal } from '@/composables/useModal'
import { UseKarteItemModalKey, UseKarteItemModalType } from '@/composables/karte/useKarteItemModel'

export default defineComponent({
  name: 'karte-item-modal',
  components: {
    ListBox,
    NormalButton,
    WhiteBackdrop
  },
  emits: ['yes'],
  setup (props, context) {
    const { show, open, dismiss } = useModal()
    const {
      karteItemList,
      selectedKarteItemId
    } = inject(UseKarteItemModalKey) as UseKarteItemModalType

    const onYes = () => {
      dismiss()
      context.emit('yes')
    }

    const onNo = () => {
      dismiss()
    }

    return {
      show,
      open,
      dismiss,
      karteItemList,
      selectedKarteItemId,
      onYes,
      onNo
    }
  }
})
