import { computed, InjectionKey, ref } from 'vue'
import { usePermission } from '@/composables/account/usePermission'
import { emailValidation } from '@/plugins/validator'
import { API, graphqlOperation } from 'aws-amplify'
import { createAccountInvitation as createAccountInvitationMutation } from '@/graphql/mutations'
import { v4 as uuid } from 'uuid'

export const useAccountInvitation = () => {
  const email = ref<string>('')
  const acceptAppointment = ref<boolean>(false)
  const {
    selectedPermissions,
    radioButtonGroups,
    permissionError,
    convertToPermissions
  } = usePermission()

  const emailError = computed(() => {
    return email.value === ''
      ? 'メールアドレスを入力してください'
      : emailValidation(email.value)
  })

  // TODO: 招待emailの送信、(一覧に再送ボタンも追加する)
  const createAccountInvitation = async () => {
    const input = {
      id: uuid(),
      email: email.value,
      permissions: convertToPermissions(),
      acceptAppointment: acceptAppointment.value
    }
    try {
      await API.graphql(graphqlOperation(createAccountInvitationMutation, { input }))
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    email,
    emailError,
    acceptAppointment,
    selectedPermissions,
    radioButtonGroups,
    permissionError,
    createAccountInvitation
  }
}

export type UseAccountInvitationType = ReturnType<typeof useAccountInvitation>
export const UseAccountInvitationKey: InjectionKey<UseAccountInvitationType> = Symbol('AccountInvitation')
