
import {
  defineComponent, inject
} from 'vue'
import LabelText from '@/components/atoms/labelText.vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'

export default defineComponent({
  name: 'karte-header',
  components: {
    LabelText
  },
  setup () {
    const {
      karte: {
        accountName,
        karteNumber
      },
      karteDateString
    } = inject(UseKarteKey) as UseKarteType
    return {
      accountName,
      karteNumber,
      karteDateString
    }
  }
})
