<template>
  <div class="karte_header">
    <div class="karte_header_charge">
      <label>担当</label>
      {{ accountName }}
    </div>
    <div class="karte_header_date">
      <label>受診日</label>
      {{ karteDateString }}
    </div>
    <div class="karte_header_number">
      <label>カルテ No.</label>
      {{ karteNumber }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'karte-header-preview',
  props: {
    accountName: String,
    karteDateString: String,
    karteNumber: String
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.karte_header {
  height: 6mm;
  width: 200mm;
  position: relative;
}

.karte_header_charge {
  position: absolute;
  left: 0;
  top: 0;
}

.karte_header_date {
  position: absolute;
  left: 50mm;
  top: 0;
}

.karte_header_number {
  position: absolute;
  left: 145mm;
  top: 0;
}
</style>
