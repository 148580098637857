import { InjectionKey } from 'vue'
import { InquiryItem as InquiryItemModel } from '@/models'
import { CreateInquiryItemInput, InquiryItem, UpdateInquiryItemInput } from '@/API'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import { createInquiryItem, updateInquiryItem as updateInquiryItemMutation } from '@/graphql/mutations'
import { getInquiryItem as getInquiryItemQuery } from '@/graphql/queries'

interface GetInquiryItemResult {
  data?: {
    getInquiryItem: InquiryItem
  }
}

export const useInquiryItemMutation = () => {
  const registerInquiryItem = async ({ inquiry }: {
    inquiry: CreateInquiryItemInput
  }) => {
    try {
      inquiry.version = '' + Math.floor(new Date().getTime() / 1000)
      await API.graphql(graphqlOperation(createInquiryItem, { input: inquiry }))
    } catch (e) {
      return Promise.reject(new Error('問診項目の登録に失敗しました'))
    }
  }

  const updateInquiryItem = async ({ inquiry }: {
    inquiry: UpdateInquiryItemInput
  }) => {
    try {
      const currentData = await _getCurrentKarteItem(inquiry.id)
      if (!currentData) {
        return Promise.reject(new Error('サーバの問診項目の取得に失敗しました'))
      }
      const input: UpdateInquiryItemInput = {
        id: inquiry.id,
        displayOrder: inquiry.displayOrder,
        version: '' + Math.floor(new Date().getTime() / 1000),
        title: inquiry.title,
        inquiryPages: inquiry.inquiryPages,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updateInquiryItemMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('問診項目の更新に失敗しました'))
    }
  }

  const deleteInquiryItem = async (id: string) => {
    try {
      const original = await DataStore.query(InquiryItemModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('問診項目の削除に失敗しました'))
      }
      await DataStore.delete<InquiryItemModel>(original[0])
    } catch (e) {
      return Promise.reject(new Error('問診項目の削除に失敗しました'))
    }
  }

  const _getCurrentKarteItem = async (id: string): Promise<InquiryItem|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getInquiryItemQuery, { id })) as GetInquiryItemResult
      return res.data?.getInquiryItem
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerInquiryItem,
    updateInquiryItem,
    deleteInquiryItem
  }
}

export type UseInquiryItemMutationType = ReturnType<typeof useInquiryItemMutation>
export const UseInquiryItemMutationKey: InjectionKey<UseInquiryItemMutationType> = Symbol('InquiryItemMutation')
