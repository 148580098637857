<template>
  <div class="multi-line-edit-box">
    <textarea
      :id="id"
      v-bind="$props"
      :value="modelValue"
      :class="['multi-line-edit-box-box', readonly ? 'edit-box-readonly' : '', $mq]"
      :disabled="readonly"
      ref="focus"
      @blur="$emit('blur',$event)"
      @input="$emit('update:modelValue', $event.target.value)"></textarea>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  name: 'multi-line-edit-box',
  props: {
    type: {
      type: String,
      default: 'text',
      validator (value) {
        return ['text', 'tel', 'number', 'password'].indexOf(value) !== -1
      }
    },
    title: String,
    placeholder: String,
    modelValue: String,
    pattern: String,
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    focus () {
      this.$refs.focus.focus()
    }
  },
  computed: {
    inputClass () {
      return [
        'multi-line-edit-box-box',
        this.readonly ? 'edit-box-readonly' : '',
        this.$mq
      ].join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-line-edit-box {
  max-height: 500px;
  min-height: 100px;
}

.multi-line-edit-box-box {
  max-height: 500px;
  min-height: 100px;
  height: 100%;
  width: 100%;
  padding: 3px 11px;
  box-sizing: border-box;
  border: 2px solid $base_color_gray;
  border-radius: $border_radius;
  background: $base_color_white;
  font-size: $base_font_size;
  transition: 0.2s;
  outline: none;

  &::placeholder {
    color: $base_color_gray;
  }

  &:focus {
    border: 2px solid $base_color_water_dark;
  }
}

.edit-box-readonly {
  background: $base_color_gray_light;
  color: $base_color_gray_dark;
}
</style>
