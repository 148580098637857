import { computed, InjectionKey, ref } from 'vue'
import { useRoute } from 'vue-router'
import { usePermission } from '@/composables/account/usePermission'
import { AccountClinic, Permission, UpdateAccountInput } from '@/API'
import { API, graphqlOperation } from 'aws-amplify'
import { getAccount as getAccountQuery } from '@/graphql/queries'
import { updateAccount as updateAccountMutation } from '@/graphql/mutations'

interface GetAccountResult {
  data?: {
    getAccount: AccountClinic
  }
}

export const useAccountClinic = () => {
  const route = useRoute()
  const id = ref<string>('')
  const name = ref<string>('')
  const permissions = ref<Permission[]>([])
  const acceptAppointment = ref<boolean>(false)
  const myself = ref<boolean>(false)
  const clinicOwner = computed(() => permissions.value.includes(Permission.clinicOwner))
  const {
    selectedPermissions,
    radioButtonGroups,
    permissionError,
    initializeSelectedPermissions,
    convertToPermissions
  } = usePermission()

  const getAccount = async () => {
    const userId = route.params.id as string // cognitoのuserIdではなく、AccountClinicのid
    try {
      const res = await API.graphql(graphqlOperation(getAccountQuery, { id: userId })) as GetAccountResult
      id.value = res.data!.getAccount.id
      name.value = res.data!.getAccount.name
      permissions.value = [...(res.data!.getAccount.permissions as Permission[])]
      acceptAppointment.value = res.data!.getAccount.acceptAppointment
      myself.value = res.data!.getAccount.myself
      initializeSelectedPermissions(permissions)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const updateAccount = async () => {
    const updateAccountInput:UpdateAccountInput = {
      id: id.value,
      permissions: convertToPermissions(),
      acceptAppointment: acceptAppointment.value
    }
    try {
      await API.graphql(graphqlOperation(updateAccountMutation, { input: updateAccountInput }))
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    getAccount,
    updateAccount,
    name,
    clinicOwner,
    permissions,
    acceptAppointment,
    myself,
    selectedPermissions,
    radioButtonGroups,
    permissionError
  }
}

export type UseAccountClinicType = ReturnType<typeof useAccountClinic>
export const UseAccountClinicKey: InjectionKey<UseAccountClinicType> = Symbol('AccountClinic')
