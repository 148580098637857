
import { defineComponent, inject, onMounted, provide, ref } from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { usePreventReload } from '@/composables/usePreventReload'
import { onBeforeRouteLeave } from 'vue-router'
import InquiryItemList from '@/views/karteData/inquiry/InquiryItemList.vue'
import { useInquiryItem, UseInquiryItemKey } from '@/composables/karteData/useInquiryItem'
import InquiryPageItemForm from '@/views/karteData/inquiry/InquiryPageItemForm.vue'
import { useInquiryItemMutation } from '@/composables/karteData/useInquiryItemMutation'

export default defineComponent({
  name: 'inquiry-item',
  components: {
    InquiryPageItemForm,
    InquiryItemList,
    Confirm,
    NormalButton,
    VueButtonFrame,
    VueTwoColumnContent,
    VueContentRemain,
    VueTitle,
    VueFrame
  },
  setup () {
    const confirmBackRef = ref<InstanceType<typeof Confirm>>()
    const confirmSaveRef = ref<InstanceType<typeof Confirm>>()
    const leaving = ref<boolean>(false)

    const inquiryItem = useInquiryItem()
    const {
      initInquiryItems,
      addedInquiries,
      updatedInquiries,
      deletedInquiryIds,
      editing
    } = inquiryItem
    provide(UseInquiryItemKey, inquiryItem)
    const {
      registerInquiryItem,
      updateInquiryItem,
      deleteInquiryItem
    } = useInquiryItemMutation()

    const { initialized, dataWrite } = inject(UseClinicKey) as UseClinicType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      lockScreen,
      unlockScreen
    } = inject(UseLockScreenKey) as UseLockScreenType

    const onBack = async () => {
      if (editing.value) {
        confirmBackRef.value?.open()
      } else {
        leaving.value = true
        await back()
      }
    }

    const yesBack = async () => {
      leaving.value = true
      await back()
    }

    const onSave = () => {
      confirmSaveRef.value?.open()
    }

    const yesSave = async () => {
      try {
        await lockScreen()
        await Promise.all(addedInquiries.value.map(async inquiry => {
          return await registerInquiryItem({ inquiry })
        }))
        await Promise.all(updatedInquiries.value.map(async inquiry => {
          return await updateInquiryItem({ inquiry })
        }))
        await Promise.all(deletedInquiryIds.value.map(async id => {
          return await deleteInquiryItem(id)
        }))
        await append('notify', '問診項目を保存しました')
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    }

    const preventReload = (event: BeforeUnloadEvent): void => {
      if (editing.value) {
        event.preventDefault()
        event.returnValue = '未保存の問診項目があります'
      }
    }
    usePreventReload(preventReload)

    onMounted(async () => {
      await lockScreen()
      await initialized.value
      initInquiryItems()
      await unlockScreen()
    })

    onBeforeRouteLeave(async (to, from, next) => {
      if (leaving.value) {
        next()
      } else if (editing.value) {
        confirmBackRef.value?.open()
        next(false)
      } else {
        next()
      }
    })

    return {
      confirmBackRef,
      confirmSaveRef,
      dataWrite,
      onBack,
      yesBack,
      onSave,
      yesSave,
      editing
    }
  }
})
