<template>
  <div class="body-frame" :class="$mq">
    <div
      class="body-inner-frame"
      :style="offset"
      @click.stop="onClick">
      <img :src="bodyImage" class="body-image" alt="">
      <svg viewBox="0 0 350 680" class="body-svg">
        <path class="body-path" :d="acuParts.path"></path>
        <g
          v-for="acu in acuList"
          :key="acu.id">
          <title>{{ acu.name }}</title>
          <circle
            :cx="acu.x"
            :cy="acu.y"
            :r="acu.selected ? radius * 1.5 : radius"
            class="acu"
            :class="{
            'acu-deprecated': acu.deprecated,
            'acu-selected': !acu.deprecated && acu.selected
          }"
            @click="onClickAcu(acu)"></circle>
        </g>
      </svg>
    </div>
    <div class="body-parts_text">{{ acuParts.name }}</div>
    <div class="selected-name-text">
      <div
        v-for="(name, i) in selectedAcuNames"
        :key="`selected-acu-name-${i}`">{{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import acuList from '@/assets/acupressureList'
import bodyBack from '@/assets/body_back.png'
import bodyFront from '@/assets/body_front.png'
import bodyLeft from '@/assets/body_left.png'
import bodyRight from '@/assets/body_right.png'

const bodyPngs = {
  'body_back.png': bodyBack,
  'body_front.png': bodyFront,
  'body_left.png': bodyLeft,
  'body_right.png': bodyRight
}

const frameSizeList = {
  mobile: 260,
  tablet: 260,
  laptop: 260,
  desktop: 450
}
const partsRatio = 0.85
const radius = 4.0

export default {
  name: 'body-parts-image',
  components: {},
  props: {
    direction: {
      type: String,
      required: true,
      default: 'front',
      validator (value) {
        return ['front', 'left', 'back', 'right'].indexOf(value) !== -1
      }
    },
    partsId: {
      type: String,
      required: true,
      default: 'head',
      validator (value) {
        return [
          'head',
          'body',
          'back',
          'rightUpperArm',
          'rightLowerArm',
          'leftUpperArm',
          'leftLowerArm',
          'rightUpperLeg',
          'rightLowerLeg',
          'leftUpperLeg',
          'leftLowerLeg'
        ].indexOf(value) !== -1
      }
    },
    acuList: Array
  },
  methods: {
    onClick (event) {
      const frameSize = frameSizeList[this.$mq]
      const ratio = (frameSize * partsRatio) / Math.max(this.acuParts.width, this.acuParts.height)
      const x = Math.ceil((event.layerX - (frameSize / 2)) / ratio) + this.acuParts.center[0]
      const y = Math.ceil((event.layerY - (frameSize / 2)) / ratio) + this.acuParts.center[1]
      this.$emit('click', {
        x,
        y
      })
    },
    onClickAcu (acu) {
      this.$emit('acu', acu)
    }
  },
  computed: {
    bodyImage () {
      return bodyPngs[acuList[this.direction].image]
    },
    acuParts () {
      if (Object.prototype.hasOwnProperty.call(acuList[this.direction].parts, this.partsId)) {
        return acuList[this.direction].parts[this.partsId]
      }

      return {
        name: 'N/A',
        path: ''
      }
    },
    offset () {
      const frameSize = frameSizeList[this.$mq]
      const ratio = (frameSize * partsRatio) / Math.max(this.acuParts.width, this.acuParts.height)
      const left = (frameSize / 2) - this.acuParts.center[0] * ratio
      const top = (frameSize / 2) - this.acuParts.center[1] * ratio
      return `transform: translate(${left}px, ${top}px) scale(${ratio}); transform-origin: 0 0;`
    },
    radius () {
      const frameSize = frameSizeList[this.$mq]
      const ratio = (frameSize * partsRatio) / Math.max(this.acuParts.width, this.acuParts.height)
      return radius / ratio
    },
    selectedAcuNames () {
      return this.acuList
        .filter(acu => acu.selected)
        .map(acu => {
          return acu.deprecated
            ? `${acu.name}(未登録)`
            : acu.name
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body-frame {
  position: relative;
  overflow: hidden;
  width: $frame-size;
  height: $frame-size;
  border-radius: $border_radius;
  border: 1px solid $base_color_gray;
  background: $base_color_white;

  &.desktop {
    width: $frame-size-desktop;
    height: $frame-size-desktop;
  }

  .body-inner-frame {
    position: relative;
    width: 350px;
    height: 680px;
    cursor: crosshair;

    .body-image,
    .body-svg {
      position: absolute;
      height: 100%;
    }

    .body-svg {
      .body-path {
        fill: $base_color_gray;
        opacity: 0.7;
      }
    }

    .acu {
      fill: $base_color_water_dark;
      cursor: pointer;
    }

    .acu-deprecated {
      fill: $base_color_red;
    }

    .acu-selected {
      fill: $base_color_green;
    }
  }

  .body-parts_text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 8px;
    font-family: sans-serif;
    font-size: $base_font_size * 1.2;
  }

  .selected-name-text {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 8px;
    display: flex;
    flex-direction: column;
    font-size: $base_font_size * 0.8;
    text-align: right;
    font-weight: bold;
  }
}
</style>
