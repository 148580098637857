import { computed, InjectionKey, ref } from 'vue'
import { AccountInvitation, ListAccountInvitationResult } from '@/API'
import { RequestStatus } from '@/composables/commonTypes'
import { API, graphqlOperation } from 'aws-amplify'
import { listAccountInvitation as listAccountInvitationQuery } from '@/graphql/queries'
import { deleteAccountInvitation as deleteAccountInvitationMutation } from '@/graphql/mutations'
import dayjs from 'dayjs'

interface listAccountInvitationQueryResult {
  data?: {
    listAccountInvitation: ListAccountInvitationResult
  }
}

export const useAccountInvitationList = () => {
  const invitationList = ref<AccountInvitation[]>([])
  const nextToken = ref<string|null>(null)
  const requestStatus = ref<RequestStatus>(RequestStatus.BeforeRequest)

  const listAccountInvitation = async (newList = false) => {
    try {
      requestStatus.value = RequestStatus.Requesting
      const res = await API.graphql(graphqlOperation(listAccountInvitationQuery, {
        nextToken: newList ? null : nextToken.value
      })) as listAccountInvitationQueryResult

      if (newList) {
        invitationList.value = [...res.data!.listAccountInvitation.items]
      } else {
        invitationList.value = [...invitationList.value, ...res.data!.listAccountInvitation.items]
      }
      nextToken.value = res.data!.listAccountInvitation.nextToken || null
      requestStatus.value = RequestStatus.Success
    } catch (e) {
      requestStatus.value = RequestStatus.Failed
      return Promise.reject(e)
    }
  }

  const deleteAccountInvitation = async (id: string) => {
    try {
      await API.graphql(graphqlOperation(deleteAccountInvitationMutation, { id }))
      invitationList.value = invitationList.value.filter(i => i.id !== id)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const invitationTitles = [
    {
      name: 'email',
      width: 2
    },
    {
      name: '有効期限',
      width: 2
    },
    {
      name: 'アクセス権',
      width: 2
    },
    {
      name: '予約可',
      width: 1,
      center: true
    },
    {
      name: '操作',
      width: 2,
      center: true
    }
  ]

  const invitationRows = computed(() => {
    return invitationList.value.map(invitation => {
      const remain = invitation.ttl - dayjs().unix() <= 24 * 60 * 60
        ? 'あと' + dayjs(invitation.ttl * 1000).diff(dayjs(), 'hours') + '時間'
        : 'あと' + dayjs(invitation.ttl * 1000).diff(dayjs(), 'days') + '日'

      return [
        {
          value: invitation.email,
          width: 2,
          type: 'text'
        },
        {
          value: remain,
          width: 2,
          type: 'text'
        },
        {
          value: invitation.permissions,
          width: 2,
          type: 'permission'
        },
        {
          value: invitation.acceptAppointment ? '〇' : '×',
          width: 1,
          type: 'text',
          center: true
        },
        {
          width: 2,
          type: 'button',
          value: '招待取り消し',
          state: 'delete',
          emitType: 'delete'
        }
      ]
    })
  })

  return {
    invitationList,
    requestStatus,
    listAccountInvitation,
    deleteAccountInvitation,
    invitationTitles,
    invitationRows
  }
}

export type UseAccountInvitationListType = ReturnType<typeof useAccountInvitationList>
export const UseAccountInvitationListKey: InjectionKey<UseAccountInvitationListType> = Symbol('AccountInvitationList')
