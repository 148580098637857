import { API, graphqlOperation } from 'aws-amplify'
import { createSetting, updateSetting } from '@/graphql/mutations'
import { Setting, CreateSettingInput, UpdateSettingInput } from '@/API'
import { getSetting } from '@/graphql/queries'
import { SettingState } from '@/composables/setting/useSetting'

interface GetSettingResult {
  data?: {
    getSetting: Setting
  }
}

export const useSettingMutation = () => {
  const registerSetting = async (clinicId: string, setting: SettingState) => {
    try {
      const currentSetting = await _fetchCurrentSetting(clinicId)
      if (currentSetting) {
        const input: CreateSettingInput = {
          id: clinicId,
          ...setting,
          _version: currentSetting._version
        }
        await API.graphql(graphqlOperation(updateSetting, { input }))
      } else {
        const input: UpdateSettingInput = {
          id: clinicId,
          ...setting
        }
        await API.graphql(graphqlOperation(createSetting, { input }))
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // 更新の前にサーバに設定データが存在するかを確認する
  const _fetchCurrentSetting = async (clinicId: string): Promise<Setting|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getSetting, { id: clinicId })) as GetSettingResult
      return res.data?.getSetting
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerSetting
  }
}

export type UseSettingMutationType = ReturnType<typeof useSettingMutation>
