
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import MultiChoiceGroup from '@/components/molecules/multiChoiceGroup.vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'

export default defineComponent({
  name: 'karte-parts-multi-choice',
  components: {
    MultiChoiceGroup
  },
  props: {
    resultIndex: {
      type: Number,
      required: true
    },
    kartePartsIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const {
      karteResult: {
        resultId,
        resultTitle,
        options,
        choices
      }
    } = inject(UseKarteKey) as UseKarteType

    const optionItems = computed(() => {
      return options.value[props.resultIndex][props.kartePartsIndex].map(o => {
        return {
          name: o,
          value: o
        }
      })
    })

    return {
      resultId,
      resultTitle,
      choices,
      optionItems
    }
  }
})
