import { inject, InjectionKey, ref } from 'vue'
import { Display, Gender, Patient as PatientModel, KarteResult } from '@/models'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import dayjs from 'dayjs'

export const usePreview = () => {
  const { name } = inject(UseAuthenticationKey) as UseAuthenticationType

  const accountName = ref(name)
  const title = ref('XXXX診察内容')
  const karteDateString = ref(dayjs().format('YYYY年M月D日(ddd)'))
  const karteNumber = ref<string>('00000001')
  const patient = ref<PatientModel>({
    id: '',
    patientNumber: '0000001',
    name: '神戸太郎',
    read: 'こうべたろう',
    gender: Gender.MALE,
    birthDate: '2000-01-01',
    private: {},
    display: Display.SHOW
  })
  const previewData = ref<KarteResult[]>([])

  // ref参照からは ref.valueを直接扱えないので、setter関数を用意している、
  const setAccountName = (value: string) => {
    accountName.value = value
  }

  const setTitle = (value: string) => {
    title.value = value
  }

  const setKarteDate = (value: string) => {
    karteDateString.value = dayjs(value, 'YYYY-MM-DD').format('YYYY年M月D日(ddd)')
  }

  const setKarteNumber = (value: string) => {
    karteNumber.value = value
  }

  const setPatient = (value: PatientModel) => {
    patient.value = value
  }

  const setPreviewData = (value: KarteResult[]) => {
    previewData.value = value
  }

  return {
    accountName,
    title,
    karteDateString,
    karteNumber,
    patient,
    previewData,
    setAccountName,
    setTitle,
    setKarteDate,
    setKarteNumber,
    setPatient,
    setPreviewData
  }
}

export type UsePreviewType = ReturnType<typeof usePreview>
export const UsePreviewKey: InjectionKey<UsePreviewType> = Symbol('Preview')
