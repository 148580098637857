
import {
  defineComponent,
  inject
} from 'vue'
import { UseSettingKey, UseSettingType } from '@/composables/setting/useSetting'
import VueLabel from '@/components/layout/vueLabel.vue'
import SelectBox from '@/components/atoms/selectBox.vue'

export default defineComponent({
  name: 'appointment',
  components: {
    VueLabel,
    SelectBox
  },
  setup () {
    const {
      editing: { appointmentTimeUnit },
      timeUnitOptions
    } = inject(UseSettingKey) as UseSettingType

    return {
      appointmentTimeUnit,
      timeUnitOptions
    }
  }
})
