
import {
  defineComponent, inject
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import Carousel from '@/components/molecules/carousel.vue'
import CarouselItemFrame from '@/components/atoms/carousel/carouselItemFrame.vue'
import BodyPartsSelector from '@/components/molecules/bodyPartsSelector.vue'
import { UseAcuItemKey, UseAcuItemType } from '@/composables/karteData/useAcuItem'

export default defineComponent({
  name: 'body-parts-list',
  components: {
    BodyPartsSelector,
    VueContent,
    CarouselItemFrame,
    Carousel
  },
  setup () {
    const {
      selectedDirection,
      selectedBodyParts,
      existsPartsList,
      allSet
    } = inject(UseAcuItemKey) as UseAcuItemType

    return {
      selectedDirection,
      selectedBodyParts,
      existsPartsList,
      allSet
    }
  }
})
