import { computed, inject, InjectionKey, ref } from 'vue'
import {
  UseKarteItemSubscriptionKey,
  UseKarteItemSubscriptionType
} from '@/composables/karteData/useKarteItemSubscription'
import { KarteResult, PartsName } from '@/models'
import { v4 as uuid } from 'uuid'

export const useKarteItemModal = () => {
  const selectedKarteItemId = ref<string|null>(null)
  const { karteItems } = inject(UseKarteItemSubscriptionKey) as UseKarteItemSubscriptionType

  const clearSelect = () => {
    selectedKarteItemId.value = null
  }

  const karteItemList = computed(() => {
    return karteItems.value
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(item => {
        return {
          value: item.id,
          name: item.title
        }
      })
  })

  const selectedKarteItems = computed<KarteResult|undefined>(() => {
    if (!selectedKarteItemId.value) {
      return undefined
    }

    const karteItem = karteItems.value.find(item => item.id === selectedKarteItemId.value)
    if (!karteItem) {
      return undefined
    }

    return {
      id: uuid(),
      karteItemId: karteItem.id,
      title: karteItem.title,
      kartePartsResult: karteItem.karteParts.map(parts => {
        return {
          id: uuid(),
          partsName: parts.partsName as PartsName,
          title: parts.title,
          options: parts.options
        }
      })
    }
  })

  return {
    selectedKarteItemId,
    clearSelect,
    karteItemList,
    selectedKarteItems
  }
}

export type UseKarteItemModalType = ReturnType<typeof useKarteItemModal>
export const UseKarteItemModalKey: InjectionKey<UseKarteItemModalType> = Symbol('KarteItemModal')
