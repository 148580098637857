
import { defineComponent, ref } from 'vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Carousel from '@/components/molecules/carousel.vue'
import CarouselItemFrame from '@/components/atoms/carousel/carouselItemFrame.vue'
import BodyPartsSelector from '@/components/molecules/bodyPartsSelector.vue'
import { useModal } from '@/composables/useModal'
import { BodyParts, Direction } from '@/models'

export default defineComponent({
  name: 'acu-parts-modal',
  components: {
    BodyPartsSelector,
    CarouselItemFrame,
    Carousel,
    NormalButton,
    WhiteBackdrop
  },
  emits: ['yes'],
  setup (props, context) {
    const carouselRef = ref<InstanceType<typeof Carousel>>()
    const { show, open: openModel, dismiss } = useModal()
    const direction = ref<Direction>(Direction.FRONT)
    const bodyParts = ref<BodyParts>(BodyParts.HEAD)

    const existsPartsList = [
      'head',
      'body',
      'back',
      'rightUpperArm',
      'rightLowerArm',
      'leftUpperArm',
      'leftLowerArm',
      'rightUpperLeg',
      'rightLowerLeg',
      'leftUpperLeg',
      'leftLowerLeg'
    ]

    const open = (d: Direction, b: BodyParts) => {
      direction.value = d
      bodyParts.value = b
      const page = ['front', 'left', 'back', 'right'].indexOf(direction.value)
      if (page >= 0) {
        carouselRef.value?.onPage(page)
      }
      openModel()
    }

    const onYes = () => {
      dismiss()
      context.emit('yes', {
        direction: direction.value,
        bodyParts: bodyParts.value
      })
    }

    return {
      show,
      open,
      dismiss,
      direction,
      bodyParts,
      existsPartsList,
      onYes
    }
  }
})
