
import {
  defineComponent, inject
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import BodyPartsImage from '@/components/molecules/bodyPartsImage.vue'
import ListTitle from '@/components/molecules/listTitle.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ListRow from '@/components/molecules/listRow.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import { UseAcuItemKey, UseAcuItemType } from '@/composables/karteData/useAcuItem'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

export default defineComponent({
  name: 'acu-form',
  components: {
    VueHeadButtonFrame,
    VueYScrollArea,
    VueContent,
    ListRow,
    NormalButton,
    ListTitle,
    BodyPartsImage
  },
  setup () {
    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      acuItems,
      selectedDirection,
      selectedBodyParts,
      selectedRowId,
      acuListTitles,
      acuPartsRows,
      acuList,
      selectRow,
      upAcuParts,
      downAcuParts,
      addAcuItem,
      deleteAcuItem,
      setAcuPosition
    } = inject(UseAcuItemKey) as UseAcuItemType

    const onClickImage = ({ x, y }: {x: number, y: number}) => {
      if (dataWrite.value) {
        setAcuPosition({ x, y })
      }
    }

    const onClick = (index: number, { emitType }: { emitType: string }) => {
      switch (emitType) {
        case 'up':
          upAcuParts(index)
          break
        case 'down':
          downAcuParts(index)
          break
        case 'delete':
          deleteAcuItem(index)
          break
      }
    }

    const onInputName = (index: number, value: string) => {
      acuItems[selectedDirection.value][selectedBodyParts.value].names.value[index] = value
    }

    return {
      dataWrite,
      selectedDirection,
      selectedBodyParts,
      selectedRowId,
      acuListTitles,
      acuPartsRows,
      acuList,
      selectRow,
      addAcuItem,
      onClickImage,
      onClick,
      onInputName
    }
  }
})
