
import {
  defineComponent, inject
} from 'vue'

import NoLabelEditBox from '@/components/molecules/editbox/noLabelEditBox.vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'

export default defineComponent({
  name: 'karteTitle',
  components: {
    NoLabelEditBox
  },
  setup () {
    const {
      karte: {
        title
      }
    } = inject(UseKarteKey) as UseKarteType
    return {
      title
    }
  }
})
