<template>
  <div class="flex_frame">
    <div class="image-frame">
      <body-parts-image-print
        class="image"
        :direction="result.direction"
        :partsId="result.partsId"
        :acuList="result.parts"></body-parts-image-print>
    </div>
    <pre class="group_data_text_value">{{ result.text }}</pre>
  </div>
</template>

<script>
import BodyPartsImagePrint from '@/components/molecules/bodyPartsImagePrint.vue'

export default {
  name: 'karte-item-preview-acu',
  components: {
    BodyPartsImagePrint
  },
  props: {
    result: Object
  }
}
</script>

<style lang="scss" scoped>
.flex_frame {
  display: flex;
}

.image-frame {
  width: 60mm;
  height: 60mm;
}

.group_data_text_value {
  font-size: 4mm;
  line-height: 6mm;
  margin: 0;
  padding: 2mm 10mm 2mm 10mm;
}
</style>
