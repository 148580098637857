
import {
  defineComponent,
  onMounted,
  provide,
  inject
} from 'vue'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useAccountClinicList, UseAccountClinicListKey } from '@/composables/account/useAccountClinicList'
import {
  useAccountInvitationList,
  UseAccountInvitationListKey
} from '@/composables/account/useAccountInvitationList'
import { useTab } from '@/composables/useTab'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import AccountList from '@/views/account/list/AccountList.vue'
import Tabs from '@/components/molecules/Tabs.vue'
import AccountInvitationList from '@/views/account/list/AccountInvitationList.vue'

export default defineComponent({
  name: 'account',
  components: {
    AccountInvitationList,
    Tabs,
    VueContentRemain,
    AccountList,
    VueHeadButtonFrame,
    BackButton,
    VueTitle,
    VueFrame,
    NormalButton
  },
  setup () {
    const { intoDashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const {
      clinicId,
      clinicOwner
    } = inject(UseClinicKey) as UseClinicType

    const account = useAccountClinicList()
    const { listAccount } = account
    provide(UseAccountClinicListKey, account)

    const invitation = useAccountInvitationList()
    const { listAccountInvitation } = invitation
    provide(UseAccountInvitationListKey, invitation)

    const { selectTab, selectedIndex, tabs } = useTab(['アカウント一覧', '招待中一覧'])

    onMounted(async () => {
      intoDashboard()
      await listAccount(true)
      await listAccountInvitation(true)
    })

    return {
      clinicId,
      clinicOwner,
      selectTab,
      selectedIndex,
      tabs
    }
  }
})
