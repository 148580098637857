
import {
  defineComponent,
  inject,
  onMounted, provide, ref
} from 'vue'
import { useSetting, UseSettingKey } from '@/composables/setting/useSetting'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import CalendarStartDay from '@/views/setting/calendarStartDay.vue'
import Appointment from '@/views/setting/appointment.vue'
import HolidayAutoClose from '@/views/setting/holidayAutoClose.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import { useSettingMutation } from '@/composables/setting/useSettingMutation'

export default defineComponent({
  name: 'setting',
  components: {
    Confirm,
    BackButton,
    VueButtonFrame,
    VueContent,
    VueTitle,
    VueFrame,
    CalendarStartDay,
    Appointment,
    HolidayAutoClose,
    NormalButton
  },
  setup () {
    const { clinicId } = inject(UseClinicKey) as UseClinicType
    const { append } = inject(UseErrorKey) as UseErrorType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const setting = useSetting()
    const {
      rowEditing,
      resetEditing
    } = setting
    provide(UseSettingKey, setting)
    const { registerSetting } = useSettingMutation()

    const confirmRef = ref<InstanceType<typeof Confirm>>()

    onMounted(() => {
      resetEditing(clinicId.value)
    })

    const onSubmit = () => {
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        await registerSetting(clinicId.value, rowEditing)
        await append('notify', '設定を変更しました。')
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      }
    }

    return {
      onSubmit,
      yes,
      back,
      confirmRef
    }
  }
})
