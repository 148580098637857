
import {
  defineComponent,
  inject
} from 'vue'
import { UseSettingKey, UseSettingType } from '@/composables/setting/useSetting'
import RadioButtonGroup from '@/components/molecules/radioButtonGroup.vue'

export default defineComponent({
  name: 'calendar-start-day',
  components: {
    RadioButtonGroup
  },
  setup () {
    const {
      editing: { calendarStartDay },
      startDayOptions
    } = inject(UseSettingKey) as UseSettingType

    return {
      calendarStartDay,
      startDayOptions
    }
  }
})
