import { InjectionKey, onUnmounted } from 'vue'

export const usePreventReload = (preventFunc: (event: BeforeUnloadEvent) => void) => {
  window.addEventListener('beforeunload', preventFunc, false)

  onUnmounted(() => {
    window.removeEventListener('beforeunload', preventFunc, false)
  })

  return {
  }
}

export type UsePreventReloadType = ReturnType<typeof usePreventReload>
export const UsePreventReloadKey: InjectionKey<UsePreventReloadType> = Symbol('PreventReload')
