
import {
  computed,
  defineComponent,
  inject, ref
} from 'vue'
import BodyPartsImage from '@/components/molecules/bodyPartsImage.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import MultiLineEditBox from '@/components/molecules/editbox/multiLineEditBox.vue'
import AcuPartsModal from '@/views/karte/Karte/modal/acuPartsModal.vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'
import {
  UseAcuItemSubscriptionKey,
  UseAcuItemSubscriptionType
} from '@/composables/karteData/useAcuItemSubscription'
import { AcuParts, BodyParts, Direction } from '@/models'
import { AcuPartsSelection } from '@/composables/karte/types'

export default defineComponent({
  name: 'karte-parts-acu',
  components: {
    AcuPartsModal,
    MultiLineEditBox,
    NormalButton,
    BodyPartsImage
  },
  props: {
    resultIndex: {
      type: Number,
      required: true
    },
    kartePartsIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const acuPartsRef = ref<InstanceType<typeof AcuPartsModal>>()
    const {
      karteResult: {
        direction,
        bodyParts,
        acuId,
        x,
        y,
        acuName,
        text
      }
    } = inject(UseKarteKey) as UseKarteType
    const { acuItems } = inject(UseAcuItemSubscriptionKey) as UseAcuItemSubscriptionType

    const onClickAcu = (acu: AcuParts) => {
      const index = acuId.value[props.resultIndex][props.kartePartsIndex].findIndex(id => id === acu.id)
      if (index >= 0) {
        // 現在の経穴設定にないものを削除すると復旧できなくなる
        acuId.value[props.resultIndex][props.kartePartsIndex].splice(index, 1)
        x.value[props.resultIndex][props.kartePartsIndex].splice(index, 1)
        y.value[props.resultIndex][props.kartePartsIndex].splice(index, 1)
        acuName.value[props.resultIndex][props.kartePartsIndex].splice(index, 1)
      } else {
        acuId.value[props.resultIndex][props.kartePartsIndex] = [...acuId.value[props.resultIndex][props.kartePartsIndex], acu.id]
        x.value[props.resultIndex][props.kartePartsIndex] = [...x.value[props.resultIndex][props.kartePartsIndex], acu.x]
        y.value[props.resultIndex][props.kartePartsIndex] = [...y.value[props.resultIndex][props.kartePartsIndex], acu.y]
        acuName.value[props.resultIndex][props.kartePartsIndex] = [...acuName.value[props.resultIndex][props.kartePartsIndex], acu.name]
      }
    }

    const onChangeParts = () => {
      const d = direction.value[props.resultIndex][props.kartePartsIndex]
      const b = bodyParts.value[props.resultIndex][props.kartePartsIndex]
      acuPartsRef.value?.open(d, b)
    }

    const onYesParts = ({ direction: d, bodyParts: b }: {
      direction: Direction,
      bodyParts: BodyParts
    }) => {
      // 選択部位が変わったら、いままで選択していた経穴はリセットする
      if (direction.value[props.resultIndex][props.kartePartsIndex] !== d ||
          bodyParts.value[props.resultIndex][props.kartePartsIndex] !== b) {
        acuId.value[props.resultIndex][props.kartePartsIndex] = []
        x.value[props.resultIndex][props.kartePartsIndex] = []
        y.value[props.resultIndex][props.kartePartsIndex] = []
        acuName.value[props.resultIndex][props.kartePartsIndex] = []
      }
      direction.value[props.resultIndex][props.kartePartsIndex] = d
      bodyParts.value[props.resultIndex][props.kartePartsIndex] = b
    }

    // カルテデータで選択済みのものに、未選択のものをマージする。
    const acuList = computed<AcuPartsSelection[]>(() => {
      const d = direction.value[props.resultIndex][props.kartePartsIndex]
      const b = bodyParts.value[props.resultIndex][props.kartePartsIndex]
      const targetAcu = !acuItems.value[d] || !acuItems.value[d][b]
        ? []
        : acuItems.value[d][b]!.parts

      let result: AcuPartsSelection[] = acuId.value[props.resultIndex][props.kartePartsIndex]
        .map((id, k) => {
          const acu = targetAcu.find(acu => acu.id === id)
          return {
            id,
            x: x.value[props.resultIndex][props.kartePartsIndex][k],
            y: y.value[props.resultIndex][props.kartePartsIndex][k],
            name: acuName.value[props.resultIndex][props.kartePartsIndex][k],
            selected: true,
            deprecated: !acu ||
              acu.x !== x.value[props.resultIndex][props.kartePartsIndex][k] ||
              acu.y !== y.value[props.resultIndex][props.kartePartsIndex][k] ||
              acu.name !== acuName.value[props.resultIndex][props.kartePartsIndex][k]
          }
        })

      result = [
        ...result,
        ...targetAcu
          .filter(acu => !acuId.value[props.resultIndex][props.kartePartsIndex].includes(acu.id))
          .map(acu => {
            return {
              ...acu,
              selected: false,
              deprecated: false
            }
          })
      ]
      return result
    })

    return {
      acuPartsRef,
      direction,
      bodyParts,
      acuId,
      x,
      y,
      acuName,
      text,
      onClickAcu,
      onChangeParts,
      onYesParts,
      acuList
    }
  }
})
