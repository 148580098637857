
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import mockImage from '@/assets/300x300.png'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'
import { KarteFileType } from '@/models'

export default defineComponent({
  name: 'karte-item-preview-images',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { fileMap } = inject(UseFileDBKey) as UseFileDBType

    const base64 = computed(() => {
      return props.result.files.map((f: KarteFileType) => {
        return f.hash === 'dummy'
          ? mockImage
          : fileMap.value[f.fileId].base64
      })
    })

    return {
      base64
    }
  }
})
