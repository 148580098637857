import { computed, InjectionKey, ref } from 'vue'
import { AccountClinic, ListAccountResult, Permission } from '@/API'
import { API, graphqlOperation } from 'aws-amplify'
import { listAccount as listAccountQuery } from '@/graphql/queries'
import { RequestStatus } from '@/composables/commonTypes'

interface listAccountQueryResult {
  data?: {
    listAccount: ListAccountResult
  }
}

export const useAccountClinicList = () => {
  const accountList = ref<AccountClinic[]>([])
  const nextToken = ref<string|null>(null)
  const requestStatus = ref<RequestStatus>(RequestStatus.BeforeRequest)

  const listAccount = async (newList = false) => {
    try {
      requestStatus.value = RequestStatus.Requesting
      const res = await API.graphql(graphqlOperation(listAccountQuery, {
        nextToken: newList ? null : nextToken.value
      })) as listAccountQueryResult

      if (newList) {
        accountList.value = [...res.data!.listAccount.items]
      } else {
        accountList.value = [...accountList.value, ...res.data!.listAccount.items]
      }
      nextToken.value = res.data!.listAccount.nextToken || null
      requestStatus.value = RequestStatus.Success
    } catch (e) {
      requestStatus.value = RequestStatus.Failed
      return Promise.reject(e)
    }
  }

  const accountTitles = [
    {
      name: '',
      width: 2
    },
    {
      name: '名前',
      width: 2
    },
    {
      name: 'アクセス権',
      width: 2
    },
    {
      name: '予約可',
      width: 1,
      center: true
    },
    {
      name: '操作',
      width: 2,
      center: true
    }
  ]

  const accountRows = computed(() => {
    return accountList.value.map(account => {
      const isOwner = (account.permissions as Permission[]).some(p => p === Permission.clinicOwner)

      const marks = []
      if (isOwner) {
        marks.push({
          title: 'オーナー',
          color: '#00597d'
        })
      }
      if (account.myself) {
        marks.push({
          title: '自分',
          color: '#6caf65'
        })
      }

      const buttons = {
        width: 2,
        type: 'buttons',
        buttons: [
          {
            value: 'アカウント設定',
            state: 'normal',
            emitType: 'change'
          }
        ]
      }
      if (isOwner || account.myself) {
        buttons.buttons.push({
          value: '',
          state: '',
          emitType: ''
        })
      } else {
        buttons.buttons.push({
          value: '削除',
          state: 'delete',
          emitType: 'delete'
        })
      }

      return [
        {
          value: marks,
          width: 2,
          type: 'circleMarks'
        },
        {
          value: account.name,
          width: 2,
          type: 'text'
        },
        {
          value: account.permissions,
          width: 2,
          type: 'permission'
        },
        {
          value: account.acceptAppointment ? '〇' : '×',
          width: 1,
          type: 'text',
          center: true
        },
        buttons
      ]
    })
  })

  return {
    accountList,
    requestStatus,
    listAccount,
    accountTitles,
    accountRows
  }
}

export type UseAccountClinicListType = ReturnType<typeof useAccountClinicList>
export const UseAccountClinicListKey: InjectionKey<UseAccountClinicListType> = Symbol('AccountClinicList')
