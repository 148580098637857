
import {
  defineComponent,
  provide,
  ref,
  onMounted, inject
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import KarteItemList from '@/views/karteData/item/KarteItemList.vue'
import { useKarteItem, UseKarteItemKey } from '@/composables/karteData/useKarteItem'
import { useKarteItemMutation } from '@/composables/karteData/useKarteItemMutation'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { usePreventReload } from '@/composables/usePreventReload'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import KartePartsForm from '@/views/karteData/item/KartePartsForm.vue'
import { onBeforeRouteLeave } from 'vue-router'

export default defineComponent({
  name: 'KarteDataItem',
  components: {
    KartePartsForm,
    VueContentRemain,
    KarteItemList,
    VueButtonFrame,
    VueTwoColumnContent,
    VueTitle,
    VueFrame,
    Confirm,
    NormalButton
  },
  setup () {
    const confirmBackRef = ref<InstanceType<typeof Confirm>>()
    const confirmSaveRef = ref<InstanceType<typeof Confirm>>()
    const leaving = ref<boolean>(false)

    const karteItem = useKarteItem()
    const {
      initKarteItems,
      editing,
      addedKarteItems,
      updatedKarteItems,
      deletedKarteItemIds
    } = karteItem
    provide(UseKarteItemKey, karteItem)
    const {
      registerKarteItem,
      updateKarteItem,
      deleteKarteItem
    } = useKarteItemMutation()

    const { initialized, dataWrite } = inject(UseClinicKey) as UseClinicType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      lockScreen,
      unlockScreen
    } = inject(UseLockScreenKey) as UseLockScreenType

    const onBack = async () => {
      if (editing.value) {
        confirmBackRef.value?.open()
      } else {
        leaving.value = true
        await back()
      }
    }

    const yesBack = async () => {
      leaving.value = true
      await back()
    }

    const onSave = () => {
      confirmSaveRef.value?.open()
    }

    const yesSave = async () => {
      try {
        await lockScreen()
        await Promise.all(addedKarteItems.value.map(async karteItem => {
          return await registerKarteItem({ karteItem })
        }))
        await Promise.all(updatedKarteItems.value.map(async karteItem => {
          return await updateKarteItem({ karteItem })
        }))
        await Promise.all(deletedKarteItemIds.value.map(async id => {
          return await deleteKarteItem(id)
        }))
        await append('notify', 'カルテ項目を保存しました')
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    }

    const preventReload = (event: BeforeUnloadEvent): void => {
      if (editing.value) {
        event.preventDefault()
        event.returnValue = '未保存のカルテ項目があります'
      }
    }
    usePreventReload(preventReload)

    onMounted(async () => {
      await lockScreen()
      await initialized.value
      initKarteItems()
      await unlockScreen()
    })

    onBeforeRouteLeave(async (to, from, next) => {
      if (leaving.value) {
        next()
      } else if (editing.value) {
        confirmBackRef.value?.open()
        next(false)
      } else {
        next()
      }
    })

    return {
      confirmBackRef,
      confirmSaveRef,
      dataWrite,
      onBack,
      yesBack,
      onSave,
      yesSave,
      editing,
      addedKarteItems,
      updatedKarteItems,
      deletedKarteItemIds
    }
  }
})
