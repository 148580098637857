
import { defineComponent, provide, onMounted, ref } from 'vue'
import { useTab } from '@/composables/useTab'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import SideMenu from '@/components/molecules/SideMenu.vue'
import AccountSettingName from '@/views/root/accountSetting/AccountSettingName.vue'
import AccountSettingPassword from '@/views/root/accountSetting/AccountSettingPassword.vue'
import { useAccountClinic, UseAccountClinicKey } from '@/composables/account/useAccountClinic'
import AccountSettingPermissions from '@/views/account/clinicAccountSetting/AccountSettingPermissions.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'

export default defineComponent({
  name: 'ClinicAccountSetting',
  components: {
    LoadingMessage,
    AccountSettingPermissions,
    AccountSettingPassword,
    AccountSettingName,
    SideMenu,
    VueTwoColumnContent,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const accountClinic = useAccountClinic()
    const {
      getAccount,
      myself,
      name
    } = accountClinic
    provide(UseAccountClinicKey, accountClinic)

    const { tabs, selectedIndex, selectTab, appendTab } = useTab([])

    const requesting = ref(true)
    onMounted(async () => {
      try {
        await getAccount()
        if (myself.value) {
          appendTab('名前変更', 'パスワード変更', 'アクセス権')
        } else {
          appendTab('アクセス権')
        }
        requesting.value = false
      } catch (e) {
        console.log(e)
      }
    })

    return {
      requesting,
      name,
      myself,
      tabs,
      selectedIndex,
      selectTab
    }
  }
})
