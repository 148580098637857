import { InjectionKey } from 'vue'
import { AcuItem as AcuItemModel } from '@/models'
import { AcuItem, CreateAcuItemInput, UpdateAcuItemInput } from '@/API'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import { createAcuItem, updateAcuItem as updateAcuItemMutation } from '@/graphql/mutations'
import { getAcuItem as getAcuItemQuery } from '@/graphql/queries'

interface GetAcuItemResult {
  data?: {
    getAcuItem: AcuItem
  }
}

export const useAcuItemMutation = () => {
  const registerAcuItem = async ({ acuItem }: {
    acuItem: CreateAcuItemInput
  }) => {
    try {
      await API.graphql(graphqlOperation(createAcuItem, { input: acuItem }))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const updateAcuItem = async ({ acuItem }: {
    acuItem: UpdateAcuItemInput
  }) => {
    try {
      const currentData = await _getCurrentAcuItem(acuItem.id)
      if (!currentData) {
        return Promise.reject(new Error('サーバの経穴リストの取得に失敗しました'))
      }
      const input:UpdateAcuItemInput = {
        id: acuItem.id,
        direction: acuItem.direction,
        bodyParts: acuItem.bodyParts,
        parts: acuItem.parts,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updateAcuItemMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('経穴リストの更新に失敗しました'))
    }
  }

  const deleteAcuItem = async (id: string) => {
    try {
      const original = await DataStore.query(AcuItemModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('経穴リストの削除に失敗しました'))
      }
      await DataStore.delete<AcuItemModel>(original[0])
      return true
    } catch (e) {
      return Promise.reject(new Error('経穴リストの削除に失敗しました'))
    }
  }

  const _getCurrentAcuItem = async (id: string): Promise<AcuItem|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getAcuItemQuery, { id })) as GetAcuItemResult
      return res.data?.getAcuItem
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerAcuItem,
    updateAcuItem,
    deleteAcuItem
  }
}

export type UseAcuItemMutationType = ReturnType<typeof useAcuItemMutation>
export const UseAcuItemMutationKey: InjectionKey<UseAcuItemMutationType> = Symbol('AcuItemMutation')
