import { computed, InjectionKey, reactive, Ref } from 'vue'
import { Permission } from '@/API'

type PermissionType = {
  account: 'write' | 'none',
  patient: 'write' | 'read' | 'none',
  calendar: 'write' | 'read' | 'none',
  data: 'write' | 'read' | 'none',
  setting: 'write' | 'none',
}

export const usePermission = () => {
  const selectedPermissions = reactive<PermissionType>({
    account: 'none',
    patient: 'none',
    calendar: 'none',
    data: 'none',
    setting: 'none'
  })

  const radioButtonGroups = [
    {
      groupId: 'account',
      group: [
        { value: 'write', name: '変更可' },
        { value: 'none', name: 'アクセス不可' }
      ],
      title: 'アカウント : '
    },
    {
      groupId: 'patient',
      group: [
        { value: 'write', name: '変更可' },
        { value: 'read', name: '閲覧のみ可' },
        { value: 'none', name: 'アクセス不可' }
      ],
      title: '患者 : '
    },
    {
      groupId: 'calendar',
      group: [
        { value: 'write', name: '変更可' },
        { value: 'read', name: '閲覧のみ可' },
        { value: 'none', name: 'アクセス不可' }
      ],
      title: '予約 : '
    },
    {
      groupId: 'data',
      group: [
        { value: 'write', name: '変更可' },
        { value: 'read', name: '閲覧のみ可' },
        { value: 'none', name: 'アクセス不可' }
      ],
      title: 'カルテ設定 : '
    },
    {
      groupId: 'setting',
      group: [
        { value: 'write', name: '変更可' },
        { value: 'none', name: 'アクセス不可' }
      ],
      title: '設定 : '
    }
  ]

  const permissionError = computed(() => {
    return (Object.keys(selectedPermissions) as (keyof PermissionType)[]).every(key => selectedPermissions[key] === 'none')
      ? '全ての権限がアクセス不可のアカウントは作成できません。'
      : undefined
  })

  const initializeSelectedPermissions = (permissions: Ref<Permission[]>) => {
    permissions.value.forEach(p => {
      switch (p) {
        case 'accountWrite':
          selectedPermissions.account = 'write'
          break
        case 'patientWrite':
          selectedPermissions.patient = 'write'
          break
        case 'patientRead':
          selectedPermissions.patient = 'read'
          break
        case 'calendarWrite':
          selectedPermissions.calendar = 'write'
          break
        case 'calendarRead':
          selectedPermissions.calendar = 'read'
          break
        case 'dataWrite':
          selectedPermissions.data = 'write'
          break
        case 'dataRead':
          selectedPermissions.data = 'read'
          break
        case 'settingWrite':
          selectedPermissions.setting = 'write'
          break
      }
    })
  }

  const convertToPermissions = (): Permission[] => {
    const result: Permission[] = [];
    (Object.keys(selectedPermissions) as (keyof PermissionType)[]).forEach(key => {
      switch (selectedPermissions[key]) {
        case 'write':
          result.push(`${key}Write` as Permission)
          break
        case 'read':
          result.push(`${key}Read` as Permission)
          break
      }
    })
    return result
  }

  return {
    selectedPermissions,
    radioButtonGroups,
    permissionError,
    initializeSelectedPermissions,
    convertToPermissions
  }
}

export type UsePermissionType = ReturnType<typeof usePermission>
export const UsePermissionKey: InjectionKey<UsePermissionType> = Symbol('Permission')
