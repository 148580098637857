
import {
  defineComponent,
  inject, ref
} from 'vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import {
  UseAccountInvitationListKey,
  UseAccountInvitationListType
} from '@/composables/account/useAccountInvitationList'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { RequestStatus } from '@/composables/commonTypes'
import ListTitle from '@/components/molecules/listTitle.vue'
import ListRow from '@/components/molecules/listRow.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import VueContent from '@/components/layout/vueContent.vue'

export default defineComponent({
  name: 'account-invitation-list',
  components: {
    VueContent,
    Confirm,
    LoadingMessage,
    VueYScrollArea,
    ListTitle,
    ListRow
  },
  setup () {
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      requestStatus,
      invitationList,
      deleteAccountInvitation,
      invitationTitles,
      invitationRows
    } = inject(UseAccountInvitationListKey) as UseAccountInvitationListType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType
    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const deleteId = ref<string>('')

    const onClick = ({ emitType, id }:{ emitType: string, id: string }) => {
      switch (emitType) {
        case 'delete':
          deleteId.value = id
          confirmRef.value?.open()
          break
      }
    }

    const yes = async () => {
      try {
        await lockScreen()
        await deleteAccountInvitation(deleteId.value)
        await append('notify', '招待を取り消しました。')
      } catch (e) {
        console.log(e)
        await append('error', '招待の取り消しに失敗しました。')
      } finally {
        await unlockScreen()
      }
    }

    return {
      RequestStatus,
      requestStatus,
      invitationList,
      invitationTitles,
      invitationRows,
      confirmRef,
      onClick,
      yes
    }
  }
})
