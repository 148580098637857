import { inject, InjectionKey, reactive, toRefs } from 'vue'
import { WeekDayShort } from '@/API'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'

export interface SettingState {
  calendarStartDay: WeekDayShort
  appointmentTimeUnit: number
  holidayAutoClose: boolean
  holidayAutoCloseSat: boolean
  holidayAutoCloseSun: boolean
  holidayAutoCloseSub: boolean
}

export const useSetting = () => {
  const editingState = reactive<SettingState>({
    calendarStartDay: WeekDayShort.sun,
    appointmentTimeUnit: 30,
    holidayAutoClose: false,
    holidayAutoCloseSat: false,
    holidayAutoCloseSun: false,
    holidayAutoCloseSub: false
  })
  const {
    fetchSetting,
    rowSetting
  } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

  const startDayOptions = [
    {
      value: 'sun',
      name: '日曜日'
    },
    {
      value: 'mon',
      name: '月曜日'
    },
    {
      value: 'sat',
      name: '土曜日'
    }
  ]

  const timeUnitOptions = [
    {
      name: '1分',
      value: 1
    },
    {
      name: '5分',
      value: 5
    },
    {
      name: '10分',
      value: 10
    },
    {
      name: '15分',
      value: 15
    },
    {
      name: '20分',
      value: 20
    },
    {
      name: '30分',
      value: 30
    },
    {
      name: '60分',
      value: 60
    }
  ]

  const resetEditing = async (clinicId: string) => {
    await fetchSetting(clinicId)
    editingState.calendarStartDay = rowSetting.calendarStartDay
    editingState.appointmentTimeUnit = rowSetting.appointmentTimeUnit
    editingState.holidayAutoClose = rowSetting.holidayAutoClose
    editingState.holidayAutoCloseSat = rowSetting.holidayAutoCloseSat
    editingState.holidayAutoCloseSun = rowSetting.holidayAutoCloseSun
    editingState.holidayAutoCloseSub = rowSetting.holidayAutoCloseSub
  }

  return {
    editing: toRefs(editingState),
    rowEditing: editingState,
    resetEditing,
    startDayOptions,
    timeUnitOptions
  }
}

export type UseSettingType = ReturnType<typeof useSetting>
export const UseSettingKey: InjectionKey<UseSettingType> = Symbol('setting')
