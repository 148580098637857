/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:d22831c5-13a8-4ae8-b24e-ab81984011eb",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_0mQaivWHL",
    "aws_user_pools_web_client_id": "ls0qsulrhrcrr5fqphtptki3f",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://szacetmazzfidaa4exqqs6cu5a.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "InquiryAnswer-prod",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "Clinic-prod",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "AccountClinic-prod",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "AccountInvitation-prod",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "KarteFile-prod",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
