
import {
  defineComponent, inject, ref
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import NormalEditBox from '@/components/molecules/editbox/normalEditBox.vue'
import ListTitle from '@/components/molecules/listTitle.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import ListRow from '@/components/molecules/listRow.vue'
import { UseKarteTemplateKey, UseKarteTemplateType } from '@/composables/karteData/useKarteTemplate'
import Preview from '@/components/organisms/preview/preview.vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'

export default defineComponent({
  name: 'karte-template-form',
  components: {
    VueYScrollArea,
    Preview,
    ListRow,
    VueContent,
    VueHeadButtonFrame,
    NormalButton,
    ListTitle,
    NormalEditBox
  },
  setup () {
    const previewRef = ref<InstanceType<typeof Preview>>()

    const { dataWrite } = inject(UseClinicKey) as UseClinicType
    const {
      karteTemplateTitles,
      titles,
      karteItemIds,
      upKarteItem,
      downKarteItem,
      addKarteItem,
      deleteKarteItem,
      selectedKarteTemplateIndex,
      KarteItemsRow,
      previewData
    } = inject(UseKarteTemplateKey) as UseKarteTemplateType

    const onClick = (index: number, { emitType }: { emitType: string }) => {
      switch (emitType) {
        case 'up':
          upKarteItem(index)
          break
        case 'down':
          downKarteItem(index)
          break
        case 'delete':
          deleteKarteItem(index)
          break
      }
    }

    const onSelectTemplate = (index: number, karteItemId: string) => {
      karteItemIds.value[selectedKarteTemplateIndex.value][index] = karteItemId
    }

    const onPreview = () => {
      previewRef.value?.setTitle(titles.value[selectedKarteTemplateIndex.value])
      previewRef.value?.setPreviewData(previewData.value)
      previewRef.value?.open()
    }

    return {
      previewRef,
      dataWrite,
      karteTemplateTitles,
      titles,
      KarteItemsRow,
      selectedKarteTemplateIndex,
      onClick,
      onSelectTemplate,
      addKarteItem,
      onPreview
    }
  }
})
