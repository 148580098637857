<template>
  <div>
    <fa-icon
      class="arrow"
      :icon="icon"></fa-icon>
  </div>
</template>

<script>
export default {
  name: 'carousel-arrow-button',
  components: {},
  props: {
    arrow: {
      type: String,
      required: true,
      validator (value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    icon () {
      return `angle-${this.arrow}`
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  color: $base_color_gray_dark;
  font-size: 2em;
  cursor: pointer;

  &:hover {
    color: $base_color_gray_light;
  }
}
</style>
