import { v4 as uuid } from 'uuid'
import { computed, inject, InjectionKey, reactive, ref, toRefs } from 'vue'
import {
  UseKarteTemplateSubscriptionKey,
  UseKarteTemplateSubscriptionType
} from '@/composables/karteData/useKarteTemplateSubscription'
import {
  UseKarteItemSubscriptionKey,
  UseKarteItemSubscriptionType
} from '@/composables/karteData/useKarteItemSubscription'
import { CreateKarteTemplateInput, KarteItemIdInput, UpdateKarteTemplateInput } from '@/API'
import { BodyParts, Direction, KarteResult } from '@/models'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

type KarteTemplateType = {
  titles: string[]
  ids: string[][]
  karteItemIds: string[][]
}

type KarteTemplateOrder = {
  id: string
  displayOrder: number
  arrayIndex: number
}

export const useKarteTemplate = () => {
  const karteTemplates = reactive<KarteTemplateType>({
    titles: [],
    ids: [],
    karteItemIds: []
  })
  const order = ref<KarteTemplateOrder[]>([])
  const selectedKarteTemplateId = ref<string>('')

  const { dataWrite } = inject(UseClinicKey) as UseClinicType
  const { karteTemplates: originalKarteTemplates } = inject(UseKarteTemplateSubscriptionKey) as UseKarteTemplateSubscriptionType
  const { karteItems } = inject(UseKarteItemSubscriptionKey) as UseKarteItemSubscriptionType

  const karteTemplateTitles = [
    {
      name: '順番',
      width: 1
    },
    {
      name: '項目名',
      width: 4
    },
    {
      name: '操作',
      width: 2
    }
  ]

  const initKarteTemplates = () => {
    const sorted = originalKarteTemplates.value.slice()
      .sort((a, b) => {
        return a.displayOrder - b.displayOrder
      })
    karteTemplates.titles = sorted.map(s => s.title)
    karteTemplates.ids = sorted.map(s => s.karteItemIds.map(item => item.id))
    karteTemplates.karteItemIds = sorted.map(s => s.karteItemIds.map(item => item.karteItemId))

    order.value = [...sorted.map((t, i) => {
      return {
        id: t.id,
        displayOrder: t.displayOrder,
        arrayIndex: i
      }
    })]
  }

  const upTemplate = () => {
    if (order.value.length === 0 || selectedKarteTemplateId.value === '') {
      return
    }
    const targetIndex = order.value.findIndex(o => o.id === selectedKarteTemplateId.value)
    const newOrder = [...order.value]
    const tmp = {
      ...newOrder[targetIndex - 1],
      displayOrder: newOrder[targetIndex].displayOrder
    }
    newOrder.splice(targetIndex - 1, 1, {
      ...newOrder[targetIndex],
      displayOrder: newOrder[targetIndex - 1].displayOrder
    })
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const downTemplate = () => {
    if (order.value.length === 0 || selectedKarteTemplateId.value === '') {
      return
    }
    const targetIndex = order.value.findIndex(o => o.id === selectedKarteTemplateId.value)
    const newOrder = [...order.value]
    const tmp = {
      ...newOrder[targetIndex + 1],
      displayOrder: newOrder[targetIndex].displayOrder
    }
    newOrder.splice(targetIndex + 1, 1, {
      ...newOrder[targetIndex],
      displayOrder: newOrder[targetIndex + 1].displayOrder
    })
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const addTemplate = () => {
    karteTemplates.titles = [...karteTemplates.titles, '']
    karteTemplates.ids = [...karteTemplates.ids, []]
    karteTemplates.karteItemIds = [...karteTemplates.karteItemIds, []]

    const newId = uuid()
    selectedKarteTemplateId.value = newId
    order.value = [
      ...order.value,
      {
        id: newId,
        displayOrder: order.value.length === 0
          ? 1
          : order.value[order.value.length - 1].displayOrder + 1,
        arrayIndex: karteTemplates.titles.length - 1
      }
    ]
  }

  const deleteTemplate = () => {
    order.value = [...order.value.filter(o => o.id !== selectedKarteTemplateId.value)]
    selectedKarteTemplateId.value = ''
  }

  const upKarteItem = (index: number) => {
    if (selectedKarteTemplateIndex.value === -1 || karteTemplates.karteItemIds.length === 0) {
      return
    }
    (Object.keys(karteTemplates) as (keyof KarteTemplateType)[]).forEach(attr => {
      if (attr === 'titles') {
        return
      }
      const newItems = [...karteTemplates[attr][selectedKarteTemplateIndex.value]]
      const tmp = newItems[index - 1]
      newItems.splice(index - 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      karteTemplates[attr][selectedKarteTemplateIndex.value] = newItems
    })
  }

  const downKarteItem = (index: number) => {
    if (selectedKarteTemplateIndex.value === -1 || karteTemplates.karteItemIds.length === 0) {
      return
    }
    (Object.keys(karteTemplates) as (keyof KarteTemplateType)[]).forEach(attr => {
      if (attr === 'titles') {
        return
      }
      const newItems = [...karteTemplates[attr][selectedKarteTemplateIndex.value]]
      const tmp = newItems[index + 1]
      newItems.splice(index + 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      karteTemplates[attr][selectedKarteTemplateIndex.value] = newItems
    })
  }

  const addKarteItem = () => {
    if (selectedKarteTemplateIndex.value === -1 || karteItems.value.length === 0) {
      return
    }
    karteTemplates.ids[selectedKarteTemplateIndex.value] =
      [...karteTemplates.ids[selectedKarteTemplateIndex.value], uuid()]
    karteTemplates.karteItemIds[selectedKarteTemplateIndex.value] =
      [...karteTemplates.karteItemIds[selectedKarteTemplateIndex.value], karteItemList.value[0].value]
  }

  const deleteKarteItem = (index: number) => {
    if (selectedKarteTemplateIndex.value === -1 || karteTemplates.ids.length === 0) {
      return
    }
    karteTemplates.ids[selectedKarteTemplateIndex.value] =
      karteTemplates.ids[selectedKarteTemplateIndex.value]
        .filter((item, j) => j !== index)
    karteTemplates.karteItemIds[selectedKarteTemplateIndex.value] =
      karteTemplates.karteItemIds[selectedKarteTemplateIndex.value]
        .filter((item, j) => j !== index)
  }

  const selectedKarteTemplateIndex = computed(() => {
    const selected = order.value.find(o => o.id === selectedKarteTemplateId.value)
    return selected ? selected.arrayIndex : -1
  })

  const listBoxKarteTemplates = computed(() => {
    return order.value
      .map(o => {
        return {
          value: o.id,
          name: karteTemplates.titles[o.arrayIndex] === ''
            ? '(タイトルなし)'
            : karteTemplates.titles[o.arrayIndex]
        }
      })
  })

  const karteItemList = computed(() => {
    return karteItems.value
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(item => {
        return {
          value: item.id,
          name: item.title
        }
      })
  })

  const KarteItemsRow = computed(() => {
    return karteTemplates.ids[selectedKarteTemplateIndex.value].map((id, j) => {
      const karteItemId = karteTemplates.karteItemIds[selectedKarteTemplateIndex.value][j]
      return {
        id: id,
        columns: [
          {
            value: `${j + 1}`,
            width: 1,
            type: 'text'
          },
          {
            items: karteItemList.value,
            selected: karteItemId,
            width: 4,
            disabled: !dataWrite.value,
            type: 'select',
            emitType: 'template'
          },
          {
            buttons: [
              {
                icon: 'arrow-up',
                state: 'normal',
                emitType: 'up',
                disabled: j === 0 || !dataWrite.value
              },
              {
                icon: 'arrow-down',
                state: 'normal',
                emitType: 'down',
                disabled: j === karteTemplates.ids[selectedKarteTemplateIndex.value].length - 1 || !dataWrite.value
              },
              {
                icon: 'times',
                state: 'delete',
                emitType: 'delete',
                disabled: !dataWrite.value
              }
            ],
            width: 2,
            type: 'iconButtons'
          }
        ]
      }
    })
  })

  const previewData = computed<KarteResult[]>(() => {
    return karteTemplates.ids[selectedKarteTemplateIndex.value].map((id, j) => {
      const karteItemId = karteTemplates.karteItemIds[selectedKarteTemplateIndex.value][j]

      const karteItem = karteItems.value.find(item => item.id === karteItemId)
      const kartePartsResult = karteItem!.karteParts.map(parts => {
        return {
          id: parts.id,
          partsName: parts.partsName,
          title: parts.title,
          startTime: '09:00',
          endTime: '11:00',
          text: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          files: [{
            id: uuid(),
            fileId: uuid(),
            name: 'sample.png',
            fileType: 'application/pdf',
            hash: 'dummy'
          }],
          options: parts.options || [],
          choices: [],
          direction: Direction.FRONT,
          bodyParts: BodyParts.HEAD,
          parts: []
        }
      })

      return {
        id,
        karteItemId,
        title: karteItem!.title,
        kartePartsResult
      }
    })
  })

  const isFirst = computed(() => {
    if (order.value.length === 0 || selectedKarteTemplateId.value === '') {
      return false
    }
    return order.value[0].id === selectedKarteTemplateId.value
  })

  const isLast = computed(() => {
    if (order.value.length === 0 || selectedKarteTemplateId.value === '') {
      return false
    }
    return order.value[order.value.length - 1].id === selectedKarteTemplateId.value
  })

  const addedKarteTemplates = computed<CreateKarteTemplateInput[]>(() => {
    return order.value
      .filter(o => originalKarteTemplates.value.find(org => org.id === o.id) === undefined)
      .map(o => {
        return {
          id: o.id,
          displayOrder: o.displayOrder,
          title: karteTemplates.titles[o.arrayIndex],
          karteItemIds: _karteItems(o.arrayIndex)
        }
      })
  })

  const updatedKarteTemplates = computed<UpdateKarteTemplateInput[]>(() => {
    return order.value
      .filter(o => {
        const org = originalKarteTemplates.value.find(org => org.id === o.id)
        if (!org) {
          return false
        }

        return o.displayOrder !== org.displayOrder ||
          karteTemplates.titles[o.arrayIndex] !== org.title ||
          org.karteItemIds.length !== karteTemplates.ids[o.arrayIndex].length ||
          org.karteItemIds.some((item, j) => {
            return karteTemplates.ids[o.arrayIndex][j] !== item.id ||
              karteTemplates.karteItemIds[o.arrayIndex][j] !== item.karteItemId
          })
      })
      .map(o => {
        return {
          id: o.id,
          displayOrder: o.displayOrder,
          title: karteTemplates.titles[o.arrayIndex],
          karteItemIds: _karteItems(o.arrayIndex)
        }
      })
  })

  const deletedKarteTemplateIds = computed(() => {
    return originalKarteTemplates.value
      .filter(org => order.value.find(o => o.id === org.id) === undefined)
      .map(org => org.id)
  })

  const editing = computed(() => {
    return addedKarteTemplates.value.length > 0 ||
      updatedKarteTemplates.value.length > 0 ||
      deletedKarteTemplateIds.value.length > 0
  })

  const _karteItems = (arrayIndex: number): KarteItemIdInput[] => {
    return karteTemplates.ids[arrayIndex].map((id, j) => {
      return {
        id,
        karteItemId: karteTemplates.karteItemIds[arrayIndex][j]
      }
    })
  }

  return {
    selectedKarteTemplateId,
    karteTemplateTitles,
    ...toRefs(karteTemplates),
    initKarteTemplates,
    upTemplate,
    downTemplate,
    addTemplate,
    deleteTemplate,
    upKarteItem,
    downKarteItem,
    addKarteItem,
    deleteKarteItem,
    selectedKarteTemplateIndex,
    listBoxKarteTemplates,
    karteItemList,
    KarteItemsRow,
    previewData,
    isFirst,
    isLast,
    addedKarteTemplates,
    updatedKarteTemplates,
    deletedKarteTemplateIds,
    editing,
    originalKarteTemplates
  }
}

export type UseKarteTemplateType = ReturnType<typeof useKarteTemplate>
export const UseKarteTemplateKey: InjectionKey<UseKarteTemplateType> = Symbol('KarteTemplate')
