<template>
  <div class="carousel-item-frame">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'carousel-item-frame'
}
</script>

<style lang="scss" scoped>
.carousel-item-frame {
  position: absolute;
  width: 33.33333%;
  height: 100%;
  box-sizing: border-box;
  transition: 0.4s;
}
</style>
