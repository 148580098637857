<template>
  <div class="body-frame">
    <div class="body-name">{{ name }}</div>
    <img :src="bodyImage" class="body-image" alt="">
    <svg viewBox="0 0 350 680" class="body-svg">
      <path
        v-for="(parts, p) in acuParts"
        :key="'parts-'+parts.name"
        class="body-path"
        :class="{
                'body-path-selected': selectedDirection === direction && selectedBodyParts === p,
                'body-path-have-parts': existsPartsList.includes(p),
                'body-path-not-have-parts': !existsPartsList.includes(p),
              }"
        :d="parts.path"
        @click="onSelectParts(p)"></path>
    </svg>
  </div>
</template>

<script>
import acuList from '@/assets/acupressureList'
import bodyBack from '@/assets/body_back.svg'
import bodyFront from '@/assets/body_front.svg'
import bodyLeft from '@/assets/body_left.svg'
import bodyRight from '@/assets/body_right.svg'

const bodySvgs = {
  'body_back.svg': bodyBack,
  'body_front.svg': bodyFront,
  'body_left.svg': bodyLeft,
  'body_right.svg': bodyRight
}

export default {
  name: 'body-parts-selector',
  components: {},
  props: {
    direction: {
      type: String,
      required: true,
      default: 'front',
      validator (value) {
        return ['front', 'left', 'back', 'right'].indexOf(value) !== -1
      }
    },
    selectedDirection: {
      type: String,
      validator (value) {
        if (!value) {
          return true
        }
        return ['front', 'left', 'back', 'right'].indexOf(value) !== -1
      }
    },
    selectedBodyParts: String,
    existsPartsList: Array
  },
  methods: {
    onSelectParts (partsId) {
      this.$emit('update:selectedDirection', this.direction)
      this.$emit('update:selectedBodyParts', partsId)
    }
  },
  computed: {
    name () {
      return acuList[this.direction].name
    },
    bodyImage () {
      return bodySvgs[acuList[this.direction].svg]
    },
    acuParts () {
      return acuList[this.direction].parts
    }
  }
}
</script>

<style lang="scss" scoped>
.body-frame {
  overflow: hidden;
  width: 100%;
  height: 100%;

  .body-name {
    padding: 5px 10px;
    font-weight: bold;
  }

  .body-image {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .body-svg {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .body-path {
      opacity: 0;
      cursor: pointer;
    }

    .body-path-have-parts {
      fill: $base_color_water;
      opacity: 0.7;
    }

    .body-path-not-have-parts {
      fill: $base_color_red_light;
      opacity: 0.7;
    }

    .body-path-selected {
      fill: $base_color_water_dark;
      opacity: 0.7;
    }
  }
}
</style>
