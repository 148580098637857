import { v4 as uuid } from 'uuid'
import { computed, inject, InjectionKey, ref } from 'vue'
import { UseKarteItemKey, UseKarteItemType } from '@/composables/karteData/useKarteItem'

export const useKarteItemOptions = () => {
  const ids = ref<string[]>([])
  const options = ref<string[]>([])
  const _partsIndex = ref<number>(0)
  const selectedOptionId = ref<string>('')

  const {
    selectedKarteItemIndex,
    options: originalOptions
  } = inject(UseKarteItemKey) as UseKarteItemType

  const initOptions = (index: number) => {
    if (selectedKarteItemIndex.value === -1 || originalOptions.value.length === 0) {
      return
    }
    _partsIndex.value = index
    if (originalOptions.value[selectedKarteItemIndex.value][index] !== null) {
      ids.value = originalOptions.value[selectedKarteItemIndex.value][index]!.map(() => uuid())
      options.value = [...originalOptions.value[selectedKarteItemIndex.value][index]!]
    } else {
      ids.value = []
      options.value = []
    }
  }

  const confirmOptions = () => {
    if (options.value.length > 0) {
      originalOptions.value[selectedKarteItemIndex.value][_partsIndex.value] = [...options.value]
    } else {
      originalOptions.value[selectedKarteItemIndex.value][_partsIndex.value] = null
    }
  }

  const upOption = () => {
    const index = _selectedOptionIndex()
    if (index === -1) {
      return
    }
    {
      const newItems = [...ids.value]
      const tmp = newItems[index - 1]
      newItems.splice(index - 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      ids.value = newItems
    }
    {
      const newItems = [...options.value]
      const tmp = newItems[index - 1]
      newItems.splice(index - 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      options.value = newItems
    }
  }

  const downOption = () => {
    const index = _selectedOptionIndex()
    if (index === -1) {
      return
    }
    {
      const newItems = [...ids.value]
      const tmp = newItems[index + 1]
      newItems.splice(index + 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      ids.value = newItems
    }
    {
      const newItems = [...options.value]
      const tmp = newItems[index + 1]
      newItems.splice(index + 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      options.value = newItems
    }
  }

  const addOption = (option: string) => {
    if (option.trim() === '') {
      return
    }
    const newId = uuid()
    selectedOptionId.value = newId

    ids.value = [...ids.value, newId]
    options.value = [...options.value, option.trim()]
  }

  const deleteOption = () => {
    const index = _selectedOptionIndex()
    if (index === -1) {
      return
    }
    ids.value = ids.value.filter((id, j) => j !== index)
    options.value = options.value.filter((o, j) => j !== index)
    selectedOptionId.value = ''
  }

  const _selectedOptionIndex = () => {
    if (options.value.length === 0 || selectedOptionId.value === '') {
      return -1
    }
    return ids.value.findIndex(id => id === selectedOptionId.value)
  }

  const listBoxOptionItems = computed(() => {
    return ids.value
      .map((id, j) => {
        return {
          value: id,
          name: options.value[j]
        }
      })
  })

  const isFirst = computed(() => {
    return _selectedOptionIndex() === 0
  })

  const isLast = computed(() => {
    return _selectedOptionIndex() === ids.value.length - 1
  })

  return {
    ids,
    options,
    selectedOptionId,
    initOptions,
    confirmOptions,
    upOption,
    downOption,
    addOption,
    deleteOption,
    listBoxOptionItems,
    isFirst,
    isLast
  }
}

export type UseKarteItemOptionsType = ReturnType<typeof useKarteItemOptions>
export const UseKarteItemOptionsKey: InjectionKey<UseKarteItemOptionsType> = Symbol('KarteItemOptions')
