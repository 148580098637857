
import {
  defineComponent,
  inject
} from 'vue'
import { UseSettingKey, UseSettingType } from '@/composables/setting/useSetting'
import ToggleSwitch from '@/components/atoms/toggleSwitch.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'

export default defineComponent({
  name: 'holiday-auto-close',
  components: {
    VueContentItemFrame,
    ToggleSwitch
  },
  setup () {
    const {
      editing: {
        holidayAutoClose,
        holidayAutoCloseSat,
        holidayAutoCloseSun,
        holidayAutoCloseSub
      }
    } = inject(UseSettingKey) as UseSettingType

    return {
      holidayAutoClose,
      holidayAutoCloseSat,
      holidayAutoCloseSun,
      holidayAutoCloseSub
    }
  }
})
