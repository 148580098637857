
import {
  defineComponent,
  inject
} from 'vue'
import { MaxAttachFileMega } from '@/config'
import IconButton from '@/components/atoms/button/iconButton.vue'
import DropArea from '@/components/molecules/dropArea.vue'
import LoadingImage from '@/components/atoms/loadingImage.vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { DropAreaFileType } from '@/composables/types'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'

export default defineComponent({
  name: 'karte-parts-images',
  components: {
    LoadingImage,
    DropArea,
    IconButton
  },
  props: {
    resultIndex: {
      type: Number,
      required: true
    },
    kartePartsIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      karteResult: {
        fileFileId
      },
      addKarteFile,
      deleteKarteFile
    } = inject(UseKarteKey) as UseKarteType
    const {
      fileMap
    } = inject(UseFileDBKey) as UseFileDBType

    const onImage = async (dropFile: DropAreaFileType) => {
      try {
        await addKarteFile(props.resultIndex, props.kartePartsIndex, dropFile)
      } catch (e) {
        await append('error', e.message)
      }
    }

    const onDropError = async (err: string) => {
      await append('error', err)
    }

    const onDeleteImage = async (index: number) => {
      try {
        await deleteKarteFile(props.resultIndex, props.kartePartsIndex, index)
      } catch (e) {
        await append('error', e.message)
      }
    }

    return {
      MaxAttachFileMega,
      fileFileId,
      fileMap,
      onImage,
      onDropError,
      onDeleteImage
    }
  }
})
