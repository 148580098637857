<template>
  <div class="radio-group-frame" :class="$mq">
    <radio-button
      v-for="(radio, index) in radios"
      :key="'radio-group-'+groupId+'-'+index"
      :checked="radio.value===modelValue"
      :disabled="readonly"
      @click="$emit('update:modelValue', radio.value)">{{ radio.name }}
    </radio-button>
  </div>
</template>

<script>
import RadioButton from '@/components/atoms/radioButton.vue'

export default {
  name: 'single-choice-group',
  components: {
    RadioButton
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    radios: Array,
    modelValue: String,
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group-frame {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  width: 85%;
  margin: 0 auto;

  &.mobile, &.tablet, &.laptop {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
