<template>
  <div class="vue-flex-column">
    <div
      v-for="(file,i) in result.files"
      :key="file.id">
      <div
        v-if="file.hash==='dummy'"
        class="filename">filename{{ (i + 1) }}.txt
      </div>
      <div
        v-else
        class="filename">{{ file.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'karte-item-preview-attach-files',
  props: {
    result: Object
  }
}
</script>

<style lang="scss" scoped>
.filename {
  font-size: 4mm;
  line-height: 4mm;
  margin: 0;
  padding: 4mm 4mm 0;
}
</style>
