<template>
  <div class="dot" :class="{selected: selected}">
  </div>
</template>

<script>
export default {
  name: 'carousel-dot',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dot {
    width: $carousel_dot_size;
    height: $carousel_dot_size;
    border-radius: $carousel_dot_size;
    background: $base_color_gray;
    cursor: pointer;
  }

  .selected {
    background: $base_color_blue_light;
  }
</style>
