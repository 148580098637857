<template>
  <div class="frame">
    <div class="ruby">{{ruby}}</div>
    <div class="value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ruby-text',
  props: {
    ruby: String
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ruby {
      font-size: 0.8em;
    }
  }
</style>
