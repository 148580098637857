// カルテ一覧の簡易版、指定日の一番新しいdisplayOrderの取得に使用する
export const listSimpleKarte = `
  query ListKartes(
    $filter: ModelKarteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKartes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        karteDate
        displayOrder
      }
    }
  }
`

export const listSimpleKarteByOrder = /* GraphQL */ `
  query ListKarteByOrder(
    $patientId: String
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKarteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKarteByOrder(
      patientId: $patientId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountName
        patientId
        karteDate
        displayOrder
        title
      }
      nextToken
      startedAt
    }
  }
`
