import { DirectiveBinding } from 'vue/dist/vue'
import { ComponentPublicInstance } from '@vue/runtime-core'

type DestructiveElement = HTMLElement & {
  '$destroy': () => void
}

export const enterDirective = {
  isLiteral: true,
  mounted (el: DestructiveElement, binding: DirectiveBinding): void {
    const action = Object.keys(binding.value)[0]
    const refName = binding.value[action]
    const next = binding.instance?.$refs[refName] as HTMLElement & ComponentPublicInstance
    const handler = (e: KeyboardEvent) => {
      onEnter(e, action, next)
    }

    el.addEventListener('keyup', handler, false)
    el.$destroy = () => {
      el.removeEventListener('keyup', handler)
    }
  },
  unmounted (el: DestructiveElement): void {
    el.$destroy()
  }
}

const onEnter = (e: KeyboardEvent, action: string, next: HTMLElement & ComponentPublicInstance) => {
  if (e.key === 'Enter') {
    switch (action) {
      case 'focus':
        next.focus()
        break
      case 'click':
        next.$emit('click')
        break
    }
  }
}
