
import { defineComponent, inject, onMounted, provide, ref } from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import KarteTemplateList from '@/views/karteData/template/KarteTemplateList.vue'
import KarteTemplateForm from '@/views/karteData/template/KarteTemplateForm.vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { usePreventReload } from '@/composables/usePreventReload'
import { onBeforeRouteLeave } from 'vue-router'
import { useKarteTemplate, UseKarteTemplateKey } from '@/composables/karteData/useKarteTemplate'
import { useKarteTemplateMutation } from '@/composables/karteData/useKarteTemplateMutation'

export default defineComponent({
  name: 'karte-template',
  components: {
    KarteTemplateForm,
    KarteTemplateList,
    Confirm,
    NormalButton,
    VueButtonFrame,
    VueTwoColumnContent,
    VueContentRemain,
    VueTitle,
    VueFrame
  },
  setup () {
    const confirmBackRef = ref<InstanceType<typeof Confirm>>()
    const confirmSaveRef = ref<InstanceType<typeof Confirm>>()
    const leaving = ref<boolean>(false)

    const karteTemplate = useKarteTemplate()
    const {
      editing,
      addedKarteTemplates,
      updatedKarteTemplates,
      deletedKarteTemplateIds
    } = karteTemplate
    provide(UseKarteTemplateKey, karteTemplate)
    const {
      registerKarteTemplate,
      updateKarteTemplate,
      deleteKarteTemplate
    } = useKarteTemplateMutation()

    const { initialized, dataWrite } = inject(UseClinicKey) as UseClinicType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      lockScreen,
      unlockScreen
    } = inject(UseLockScreenKey) as UseLockScreenType

    const onBack = async () => {
      if (editing.value) {
        confirmBackRef.value?.open()
      } else {
        leaving.value = true
        await back()
      }
    }

    const yesBack = async () => {
      leaving.value = true
      await back()
    }

    const onSave = () => {
      confirmSaveRef.value?.open()
    }

    const yesSave = async () => {
      try {
        await lockScreen()
        await Promise.all(addedKarteTemplates.value.map(async karteTemplate => {
          return await registerKarteTemplate({ karteTemplate })
        }))
        await Promise.all(updatedKarteTemplates.value.map(async karteTemplate => {
          return await updateKarteTemplate({ karteTemplate })
        }))
        await Promise.all(deletedKarteTemplateIds.value.map(async id => {
          return await deleteKarteTemplate(id)
        }))
        await append('notify', 'カルテテンプレートを保存しました')
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    }

    const preventReload = (event: BeforeUnloadEvent): void => {
      if (editing.value) {
        event.preventDefault()
        event.returnValue = '未保存のテンプレートがあります'
      }
    }
    usePreventReload(preventReload)

    onMounted(async () => {
      await lockScreen()
      await initialized.value
      karteTemplate.initKarteTemplates()
      await unlockScreen()
    })

    onBeforeRouteLeave(async (to, from, next) => {
      if (leaving.value) {
        next()
      } else if (editing.value) {
        confirmBackRef.value?.open()
        next(false)
      } else {
        next()
      }
    })

    return {
      confirmBackRef,
      confirmSaveRef,
      dataWrite,
      onBack,
      yesBack,
      onSave,
      yesSave,
      editing
    }
  }
})
