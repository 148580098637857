
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'
import VueSubContent from '@/components/layout/vueSubContent.vue'
import VueHeadButtonFrame from '@/components/layout/vueHeadButtonFrame.vue'
import IconButtonMiddle from '@/components/atoms/button/iconButtonMiddle.vue'
import KartePartsTimeRange from '@/views/karte/Karte/parts/kartePartsTimeRange.vue'
import KartePartsSingleLine from '@/views/karte/Karte/parts/kartePartsSingleLine.vue'
import KartePartsMultiLine from '@/views/karte/Karte/parts/kartePartsMultiLine.vue'
import KartePartsImages from '@/views/karte/Karte/parts/kartePartsImages.vue'
import KartePartsImageAndText from '@/views/karte/Karte/parts/kartePartsImageAndText.vue'
import KartePartsAttachFiles from '@/views/karte/Karte/parts/kartePartsAttachFiles.vue'
import KartePartsSingleChoice from '@/views/karte/Karte/parts/kartePartsSingleChoice.vue'
import KartePartsMultiChoice from '@/views/karte/Karte/parts/kartePartsMultiChoice.vue'
import KartePartsAcu from '@/views/karte/Karte/parts/kartePartsAcu.vue'

export default defineComponent({
  name: 'karte-result',
  components: {
    KartePartsAcu,
    KartePartsMultiChoice,
    KartePartsSingleChoice,
    KartePartsAttachFiles,
    KartePartsImageAndText,
    KartePartsImages,
    KartePartsMultiLine,
    KartePartsSingleLine,
    VueHeadButtonFrame,
    VueSubContent,
    IconButtonMiddle,
    KartePartsTimeRange
  },
  props: {
    resultId: {
      type: String,
      required: true
    },
    resultIndex: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const {
      upResult,
      downResult,
      deleteResult,
      karteResult,
      karteResultControlMap
    } = inject(UseKarteKey) as UseKarteType

    const onHistory = () => {
      // this.$emit('history', { karteItemId: this.karteItem.karteItemId })
    }

    const title = computed(() => {
      return karteResult.title.value[props.resultIndex]
    })

    const kartePartsResult = computed(() => {
      return karteResult.resultId.value[props.resultIndex].map((resultId, j) => {
        return {
          id: resultId,
          partsName: karteResult.partsName.value[props.resultIndex][j]
        }
      })
    })

    return {
      onHistory,
      upResult,
      downResult,
      deleteResult,
      title,
      kartePartsResult,
      karteResultControlMap
    }
  }
})
