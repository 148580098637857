<template>
  <div class="vue-flex-row frame">
    <template v-if="result.title">
      <label class="group_data_text_label">{{ result.title }}</label>
      <pre class="group_data_time_value">{{ startTime }} 〜 {{ endTime }}</pre>
    </template>
    <template v-else>
      <pre class="group_data_time_value">{{ startTime }} 〜 {{ endTime }}</pre>
    </template>
  </div>
</template>

<script>
export default {
  name: 'karte-item-preview-time-range',
  props: {
    result: Object
  },
  computed: {
    startTime () {
      return this.result.startTime.substr(0, 2) + ':' + this.result.startTime.substr(2, 2)
    },
    endTime () {
      return this.result.endTime.substr(0, 2) + ':' + this.result.endTime.substr(2, 2)
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}

.frame {
  height: 100%;
  align-items: center;
}

.group_data_text_label {
  display: inline-block;
  font-size: 4mm;
  height: 6mm;
  line-height: 6mm;
  padding-left: 2mm;
  width: 20mm;
}

.group_data_time_value {
  font-size: 6mm;
  line-height: 6mm;
  margin: 0;
  padding: 2mm 10mm 2mm 10mm;
}
</style>
