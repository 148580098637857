<template>
  <div>
    <img
      class="broken-image"
      src="@/assets/broken.png"
      alt="image">
    <div class="broken-text" v-if="loading">読み込み中</div>
    <div class="broken-text" v-else>読み込みに<br>失敗しました</div>
  </div>
</template>

<script>
import * as config from '@/config'

export default {
  name: 'loading-image',
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, config.LoadingTimeout)
  }
}
</script>

<style lang="scss" scoped>
.broken-image {
  width: 100%;
  height: 100%;
}

.broken-text {
  position: relative;
  top: -100px;
  width: 60%;
  margin: 0 auto;
  font-size: 0.8em;
  color: $base_color_red_dark;
  text-align: center;
}

</style>
