<template>
  <div class="button-frame">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-head-button-frame'
}
</script>

<style lang="scss" scoped>
  .button-frame {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
  }
</style>
