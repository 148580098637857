import { v4 as uuid } from 'uuid'
import { computed, inject, InjectionKey, reactive, ref, toRefs } from 'vue'
import {
  UseInquiryItemSubscriptionKey,
  UseInquiryItemSubscriptionType
} from '@/composables/karteData/useInquiryItemSubscription'
import {
  CreateInquiryItemInput,
  InquiryPageInput,
  InquiryPageItemInput,
  InquiryType,
  UpdateInquiryItemInput
} from '@/API'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

type InquiryItemsType = {
  titles: string[]
  pageIds: string[][]
  pageTitles: string[][]
  pageItemIds: string[][][]
  valueTypes: string[][][]
  required: boolean[][][]
  pageItemTitles: string[][][]
  options: (string[] | null)[][][]
}
type InquiryItemOrder = {
  id: string
  displayOrder: number
  arrayIndex: number
}

export const useInquiryItem = () => {
  const inquiryItems = reactive<InquiryItemsType>({
    titles: [],
    pageIds: [],
    pageTitles: [],
    pageItemIds: [],
    valueTypes: [],
    required: [],
    pageItemTitles: [],
    options: []
  })
  const order = ref<InquiryItemOrder[]>([])
  const selectedInquiryItemId = ref<string>('')

  const { dataWrite } = inject(UseClinicKey) as UseClinicType
  const { inquiryItems: originalInquiryItems } = inject(UseInquiryItemSubscriptionKey) as UseInquiryItemSubscriptionType

  const inquiryPageItemTitles = [
    { name: '順番', width: 1 },
    { name: '回答タイプ', width: 3 },
    { name: '質問', width: 3 },
    { name: '選択肢', width: 3 },
    { name: '必須', width: 1 },
    { name: '操作', width: 3 }
  ]

  const inquiryTypeList = [
    { value: 'text', name: 'テキスト' },
    { value: 'number', name: '数字' },
    { value: 'date', name: '日付' },
    { value: 'time', name: '時刻' },
    { value: 'singleChoice', name: '単一選択' },
    { value: 'multiChoice', name: '複数選択' }
  ]

  const initInquiryItems = () => {
    const sorted = originalInquiryItems.value
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder)

    inquiryItems.titles = sorted.map(inq => inq.title)
    inquiryItems.pageIds = sorted.map(inq => inq.inquiryPages.map(p => p.id))
    inquiryItems.pageTitles = sorted.map(inq => inq.inquiryPages.map(p => p.pageTitle))
    inquiryItems.pageItemIds = sorted.map(inq => inq.inquiryPages.map(p => p.inquiries.map(i => i.id)))
    inquiryItems.valueTypes = sorted.map(inq => inq.inquiryPages.map(p => p.inquiries.map(i => i.valueType)))
    inquiryItems.required = sorted.map(inq => inq.inquiryPages.map(p => p.inquiries.map(i => i.required)))
    inquiryItems.pageItemTitles = sorted.map(inq => inq.inquiryPages.map(p => p.inquiries.map(i => i.title)))
    inquiryItems.options = sorted.map(inq => inq.inquiryPages.map(p => p.inquiries.map(i => i.options || null)))

    order.value = [...sorted.map((s, i) => {
      return {
        id: s.id,
        displayOrder: s.displayOrder,
        arrayIndex: i
      }
    })]
  }

  const addItem = () => {
    inquiryItems.titles = [...inquiryItems.titles, '']
    inquiryItems.pageIds = [...inquiryItems.pageIds, []]
    inquiryItems.pageTitles = [...inquiryItems.pageTitles, []]
    inquiryItems.pageItemIds = [...inquiryItems.pageItemIds, []]
    inquiryItems.valueTypes = [...inquiryItems.valueTypes, []]
    inquiryItems.required = [...inquiryItems.required, []]
    inquiryItems.pageItemTitles = [...inquiryItems.pageItemTitles, []]
    inquiryItems.options = [...inquiryItems.options, []]

    const newId = uuid()
    selectedInquiryItemId.value = newId
    order.value = [
      ...order.value,
      {
        id: newId,
        displayOrder: order.value.length === 0
          ? 1
          : order.value[order.value.length - 1].displayOrder + 1,
        arrayIndex: inquiryItems.titles.length - 1
      }
    ]
  }

  const deleteItem = () => {
    order.value = [...order.value.filter(o => o.id !== selectedInquiryItemId.value)]
    selectedInquiryItemId.value = ''
  }

  const upItem = () => {
    if (order.value.length === 0 || selectedInquiryItemId.value === '') {
      return
    }
    const targetIndex = order.value.findIndex(o => o.id === selectedInquiryItemId.value)
    const newOrder = [...order.value]
    const tmp = {
      ...newOrder[targetIndex - 1],
      displayOrder: newOrder[targetIndex].displayOrder
    }
    newOrder.splice(targetIndex - 1, 1, {
      ...newOrder[targetIndex],
      displayOrder: newOrder[targetIndex - 1].displayOrder
    })
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const downItem = () => {
    if (order.value.length === 0 || selectedInquiryItemId.value === '') {
      return
    }
    const targetIndex = order.value.findIndex(o => o.id === selectedInquiryItemId.value)
    const newOrder = [...order.value]
    const tmp = {
      ...newOrder[targetIndex + 1],
      displayOrder: newOrder[targetIndex].displayOrder
    }
    newOrder.splice(targetIndex + 1, 1, {
      ...newOrder[targetIndex],
      displayOrder: newOrder[targetIndex + 1].displayOrder
    })
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const addInquiryPage = () => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    inquiryItems.pageIds[selectedInquiryItemIndex.value] = [...inquiryItems.pageIds[selectedInquiryItemIndex.value], uuid()]
    inquiryItems.pageTitles[selectedInquiryItemIndex.value] = [...inquiryItems.pageTitles[selectedInquiryItemIndex.value], '']
    inquiryItems.pageItemIds[selectedInquiryItemIndex.value] = [...inquiryItems.pageItemIds[selectedInquiryItemIndex.value], []]
    inquiryItems.valueTypes[selectedInquiryItemIndex.value] = [...inquiryItems.valueTypes[selectedInquiryItemIndex.value], []]
    inquiryItems.required[selectedInquiryItemIndex.value] = [...inquiryItems.required[selectedInquiryItemIndex.value], []]
    inquiryItems.pageItemTitles[selectedInquiryItemIndex.value] = [...inquiryItems.pageItemTitles[selectedInquiryItemIndex.value], []]
    inquiryItems.options[selectedInquiryItemIndex.value] = [...inquiryItems.options[selectedInquiryItemIndex.value], []]
  }

  const deleteInquiryPage = (pageIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    inquiryItems.pageIds[selectedInquiryItemIndex.value] = inquiryItems.pageIds[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
    inquiryItems.pageTitles[selectedInquiryItemIndex.value] = inquiryItems.pageTitles[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
    inquiryItems.pageItemIds[selectedInquiryItemIndex.value] = inquiryItems.pageItemIds[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
    inquiryItems.valueTypes[selectedInquiryItemIndex.value] = inquiryItems.valueTypes[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
    inquiryItems.required[selectedInquiryItemIndex.value] = inquiryItems.required[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
    inquiryItems.pageItemTitles[selectedInquiryItemIndex.value] = inquiryItems.pageItemTitles[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
    inquiryItems.options[selectedInquiryItemIndex.value] = inquiryItems.options[selectedInquiryItemIndex.value]
      .filter((p, j) => j !== pageIndex)
  }

  const upInquiryPage = (pageIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    (Object.keys(inquiryItems) as (keyof InquiryItemsType)[]).forEach(attr => {
      if (attr === 'titles') {
        return
      }
      const newItems = [...inquiryItems[attr][selectedInquiryItemIndex.value]]
      const tmp = newItems[pageIndex - 1]
      newItems.splice(pageIndex - 1, 1, newItems[pageIndex])
      newItems.splice(pageIndex, 1, tmp)
      inquiryItems[attr][selectedInquiryItemIndex.value] = newItems as (string[] & string[][] & boolean[][] & (string[] | null)[][])
    })
  }

  const downInquiryPage = (pageIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    (Object.keys(inquiryItems) as (keyof InquiryItemsType)[]).forEach(attr => {
      if (attr === 'titles') {
        return
      }
      const newItems = [...inquiryItems[attr][selectedInquiryItemIndex.value]]
      const tmp = newItems[pageIndex + 1]
      newItems.splice(pageIndex + 1, 1, newItems[pageIndex])
      newItems.splice(pageIndex, 1, tmp)
      inquiryItems[attr][selectedInquiryItemIndex.value] = newItems as (string[] & string[][] & boolean[][] & (string[] | null)[][])
    })
  }

  const addInquiryPageItem = (pageIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex] =
      [...inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex], uuid()]
    inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex] =
      [...inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex], 'text']
    inquiryItems.required[selectedInquiryItemIndex.value][pageIndex] =
      [...inquiryItems.required[selectedInquiryItemIndex.value][pageIndex], false]
    inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex] =
      [...inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex], '']
    inquiryItems.options[selectedInquiryItemIndex.value][pageIndex] =
      [...inquiryItems.options[selectedInquiryItemIndex.value][pageIndex], null]
  }

  const deleteInquiryPageItem = (pageIndex: number, itemIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex] =
      inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex]
        .filter((p, k) => k !== itemIndex)
    inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex] =
      inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex]
        .filter((p, k) => k !== itemIndex)
    inquiryItems.required[selectedInquiryItemIndex.value][pageIndex] =
      inquiryItems.required[selectedInquiryItemIndex.value][pageIndex]
        .filter((p, k) => k !== itemIndex)
    inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex] =
      inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex]
        .filter((p, k) => k !== itemIndex)
    inquiryItems.options[selectedInquiryItemIndex.value][pageIndex] =
      inquiryItems.options[selectedInquiryItemIndex.value][pageIndex]
        .filter((p, k) => k !== itemIndex)
  }

  const upInquiryPageItem = (pageIndex: number, itemIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    {
      const newItems = [...inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex - 1]
      newItems.splice(itemIndex - 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex - 1]
      newItems.splice(itemIndex - 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex - 1]
      newItems.splice(itemIndex - 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.required[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex - 1]
      newItems.splice(itemIndex - 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.required[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.options[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex - 1]
      newItems.splice(itemIndex - 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.options[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
  }

  const downInquiryPageItem = (pageIndex: number, itemIndex: number) => {
    if (selectedInquiryItemIndex.value === -1) {
      return
    }
    {
      const newItems = [...inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex + 1]
      newItems.splice(itemIndex + 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.pageItemIds[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex + 1]
      newItems.splice(itemIndex + 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex + 1]
      newItems.splice(itemIndex + 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.valueTypes[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.required[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex + 1]
      newItems.splice(itemIndex + 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.required[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
    {
      const newItems = [...inquiryItems.options[selectedInquiryItemIndex.value][pageIndex]]
      const tmp = newItems[itemIndex + 1]
      newItems.splice(itemIndex + 1, 1, newItems[itemIndex])
      newItems.splice(itemIndex, 1, tmp)
      inquiryItems.options[selectedInquiryItemIndex.value][pageIndex] = newItems
    }
  }

  const selectedInquiryItemIndex = computed(() => {
    const selected = order.value.find(o => o.id === selectedInquiryItemId.value)
    return selected ? selected.arrayIndex : -1
  })

  const listBoxInquiryItems = computed(() => {
    return order.value
      .map(o => {
        return {
          value: o.id,
          name: inquiryItems.titles[o.arrayIndex] === ''
            ? '(タイトルなし)'
            : inquiryItems.titles[o.arrayIndex]
        }
      })
  })

  const inquiryPageItemRows = computed(() => {
    return inquiryItems.pageItemIds[selectedInquiryItemIndex.value].map((pageItemIds, j) => {
      return pageItemIds.map((pageItemId, k) => {
        const selectedType = inquiryItems.valueTypes[selectedInquiryItemIndex.value][j][k]
        const pageItemTitle = inquiryItems.pageItemTitles[selectedInquiryItemIndex.value][j][k]
        const options = inquiryItems.options[selectedInquiryItemIndex.value][j][k]
        const required = inquiryItems.required[selectedInquiryItemIndex.value][j][k]

        const optionColumn = ['singleChoice', 'multiChoice'].includes(selectedType)
          ? !options || options.length === 0
            ? {
              button: options,
              width: 3,
              state: 'normal',
              value: '選択肢編集',
              disabled: !dataWrite.value,
              type: 'button',
              emitType: 'option'
            }
            : {
              values: options,
              width: 3,
              type: 'textMultiline',
              emitType: 'option'
            }
          : {
            value: '',
            width: 3,
            type: 'text'
          }

        return {
          id: pageItemId,
          columns: [
            { value: `${k + 1}`, width: 1, type: 'text' },
            { items: inquiryTypeList, selected: selectedType, width: 3, type: 'select', disabled: !dataWrite.value, emitType: 'valueType' },
            { value: pageItemTitle, width: 3, type: 'edit', readonly: !dataWrite.value, emitType: 'pageItemTitle' },
            optionColumn,
            { value: required, width: 1, type: 'checkbox', emitType: 'required' },
            {
              buttons: [
                {
                  icon: 'arrow-up',
                  state: 'normal',
                  emitType: 'up',
                  disabled: k === 0 || !dataWrite.value
                },
                {
                  icon: 'arrow-down',
                  state: 'normal',
                  emitType: 'down',
                  disabled: k === pageItemIds.length - 1 || !dataWrite.value
                },
                {
                  icon: 'times',
                  state: 'delete',
                  emitType: 'delete',
                  disabled: !dataWrite.value
                }
              ],
              width: 3,
              type: 'iconButtons'
            }
          ]
        }
      })
    })
  })

  const isFirst = computed(() => {
    if (order.value.length === 0 || selectedInquiryItemId.value === '') {
      return false
    }
    return order.value[0].id === selectedInquiryItemId.value
  })

  const isLast = computed(() => {
    if (order.value.length === 0 || selectedInquiryItemId.value === '') {
      return false
    }
    return order.value[order.value.length - 1].id === selectedInquiryItemId.value
  })

  const addedInquiries = computed<CreateInquiryItemInput[]>(() => {
    return order.value
      .filter(o => originalInquiryItems.value.find(org => org.id === o.id) === undefined)
      .map(o => {
        return {
          id: o.id,
          displayOrder: o.displayOrder,
          version: '',
          title: inquiryItems.titles[o.arrayIndex],
          inquiryPages: _inquiryPages(o.arrayIndex)
        }
      })
  })

  const updatedInquiries = computed<UpdateInquiryItemInput[]>(() => {
    return order.value
      .filter(o => {
        const org = originalInquiryItems.value.find(org => org.id === o.id)
        if (!org) {
          return false
        }
        return (
          o.displayOrder !== org.displayOrder ||
          inquiryItems.titles[o.arrayIndex] !== org.title ||
          inquiryItems.pageIds[o.arrayIndex].length !== org.inquiryPages.length ||
          org.inquiryPages.some((p, j) => {
            return (
              inquiryItems.pageIds[o.arrayIndex][j] !== p.id ||
              inquiryItems.pageTitles[o.arrayIndex][j] !== p.pageTitle ||
              inquiryItems.pageItemIds[o.arrayIndex][j].length !== p.inquiries.length ||
              p.inquiries.some((inq, k) => {
                return (
                  inquiryItems.pageItemIds[o.arrayIndex][j][k] !== inq.id ||
                  inquiryItems.valueTypes[o.arrayIndex][j][k] !== inq.valueType ||
                  inquiryItems.pageItemTitles[o.arrayIndex][j][k] !== inq.title ||
                  inquiryItems.required[o.arrayIndex][j][k] !== inq.required ||
                  JSON.stringify(inquiryItems.options[o.arrayIndex][j][k]) !== JSON.stringify(inq.options)
                )
              })
            )
          })
        )
      })
      .map(o => {
        return {
          id: o.id,
          displayOrder: o.displayOrder,
          version: '',
          title: inquiryItems.titles[o.arrayIndex],
          inquiryPages: _inquiryPages(o.arrayIndex)
        }
      })
  })

  const deletedInquiryIds = computed(() => {
    return originalInquiryItems.value
      .filter(org => order.value.find(o => o.id === org.id) === undefined)
      .map(org => org.id)
  })

  const editing = computed(() => {
    return (
      addedInquiries.value.length > 0 ||
      updatedInquiries.value.length > 0 ||
      deletedInquiryIds.value.length > 0
    )
  })

  const _inquiryPages = (arrayIndex: number): InquiryPageInput[] => {
    return inquiryItems.pageIds[arrayIndex].map((pageId, j) => {
      const inquiries: InquiryPageItemInput[] = inquiryItems.pageItemIds[arrayIndex][j].map((pageItemId, k) => {
        return {
          id: pageItemId,
          valueType: inquiryItems.valueTypes[arrayIndex][j][k] as InquiryType,
          title: inquiryItems.pageItemTitles[arrayIndex][j][k],
          required: inquiryItems.required[arrayIndex][j][k],
          options: inquiryItems.options[arrayIndex][j][k]
        }
      })
      return {
        id: pageId,
        pageTitle: inquiryItems.pageTitles[arrayIndex][j],
        inquiries: inquiries
      }
    })
  }

  return {
    inquiryPageItemTitles,
    inquiryTypeList,
    ...toRefs(inquiryItems),
    selectedInquiryItemId,
    initInquiryItems,
    addItem,
    deleteItem,
    upItem,
    downItem,
    addInquiryPage,
    deleteInquiryPage,
    upInquiryPage,
    downInquiryPage,
    addInquiryPageItem,
    deleteInquiryPageItem,
    upInquiryPageItem,
    downInquiryPageItem,
    selectedInquiryItemIndex,
    listBoxInquiryItems,
    inquiryPageItemRows,
    isFirst,
    isLast,
    addedInquiries,
    updatedInquiries,
    deletedInquiryIds,
    editing
  }
}

export type UseInquiryItemType = ReturnType<typeof useInquiryItem>
export const UseInquiryItemKey: InjectionKey<UseInquiryItemType> = Symbol('InquiryItem')
