
import {
  defineComponent,
  inject
} from 'vue'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import { useLogout } from '@/composables/useLogout'

export default defineComponent({
  name: 'timeout',
  components: {
    VueContentItemFrame,
    VueContent,
    VueFrame,
    NormalButton
  },
  setup () {
    const { logout } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { signOutAll } = useLogout()

    const onLogout = async () => {
      try {
        await signOutAll()
        await logout()
      } catch (e) {
        console.log(e)
      }
    }

    return {
      onLogout
    }
  }
})
