
import {
  defineComponent,
  ref
} from 'vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import NormalEditBox from '@/components/molecules/editbox/normalEditBox.vue'
import ListBox from '@/components/atoms/listBox.vue'
import { useModal } from '@/composables/useModal'
import { useInquiryItemOptions } from '@/composables/karteData/useInquiryItemOptions'

export default defineComponent({
  name: 'inquiry-option-modal',
  components: {
    ListBox,
    NormalEditBox,
    NormalButton,
    WhiteBackdrop
  },
  setup () {
    const { show, open: openModal, dismiss } = useModal()
    const {
      selectedOptionId,
      initOptions,
      confirmOptions,
      upOption,
      downOption,
      addOption,
      deleteOption,
      listBoxOptionItems,
      isFirst,
      isLast
    } = useInquiryItemOptions()
    const optionText = ref<string>('')

    const onAddOption = () => {
      if (optionText.value.trim() === '') {
        return
      }
      addOption(optionText.value)
      optionText.value = ''
    }

    const open = (pageIndex: number, itemIndex: number) => {
      initOptions(pageIndex, itemIndex)
      openModal()
    }

    const onYes = () => {
      optionText.value = ''
      confirmOptions()
      dismiss()
    }

    const onNo = () => {
      optionText.value = ''
      dismiss()
    }

    return {
      show,
      onAddOption,
      open,
      dismiss,
      onYes,
      onNo,
      optionText,
      selectedOptionId,
      initOptions,
      confirmOptions,
      upOption,
      downOption,
      addOption,
      deleteOption,
      listBoxOptionItems,
      isFirst,
      isLast
    }
  }
})
