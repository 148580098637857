
import {
  defineComponent,
  inject,
  watch
} from 'vue'
import TimeEditBox from '@/components/molecules/timeEditBox.vue'
import { UseKarteKey, UseKarteType } from '@/composables/karte/useKarte'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import { addTime } from '@/plugins/functions'

export default defineComponent({
  name: 'karte-parts-time-range',
  components: {
    TimeEditBox
  },
  props: {
    resultIndex: {
      type: Number,
      required: true
    },
    kartePartsIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const {
      karteResult: {
        resultTitle,
        startTime,
        endTime
      }
    } = inject(UseKarteKey) as UseKarteType
    const {
      setting: {
        appointmentTimeUnit
      }
    } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

    watch(
      () => startTime.value[props.resultIndex][props.kartePartsIndex],
      to => {
        if (to.length === 4 && to >= endTime.value[props.resultIndex][props.kartePartsIndex]) {
          endTime.value[props.resultIndex][props.kartePartsIndex] = addTime(to, appointmentTimeUnit.value)
        }
      },
      { deep: true }
    )

    watch(
      () => endTime.value[props.resultIndex][props.kartePartsIndex],
      to => {
        if (to.length === 4 && to <= startTime.value[props.resultIndex][props.kartePartsIndex]) {
          startTime.value[props.resultIndex][props.kartePartsIndex] = addTime(to, -appointmentTimeUnit.value)
        }
      },
      { deep: true }
    )

    return {
      resultTitle,
      startTime,
      endTime,
      appointmentTimeUnit
    }
  }
})
