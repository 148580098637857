import { v4 as uuid } from 'uuid'
import { computed, inject, InjectionKey, reactive, Ref, ref, toRefs, watch } from 'vue'
import {
  BodyParts,
  Direction,
  CreateAcuItemInput,
  UpdateAcuItemInput,
  AcuPartsInput
} from '@/API'
import {
  UseAcuItemSubscriptionKey,
  UseAcuItemSubscriptionType
} from '@/composables/karteData/useAcuItemSubscription'
import { AcuPartsSelection } from '@/composables/karte/types'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

type AcuItemsType = {
  id: string
  ids: string[]
  names: string[]
  x: (number|null)[]
  y: (number|null)[]
}
type AcuItemBodyPartsMap = {[key:string]: AcuItemsType}
type AcuItemDirectionMap = {[key:string]: AcuItemBodyPartsMap}

type AcuItemRefType = { [key: string]: { [key: string]: { names: Ref<string[]>, x: Ref<(number | null)[]>, y: Ref<(number | null)[]> } } }

export const useAcuItem = () => {
  const acuItems:AcuItemDirectionMap = {
    front: {
      head: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      body: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] })
    },
    right: {
      head: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      body: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      back: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] })
    },
    back: {
      head: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      back: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] })
    },
    left: {
      head: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      body: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      back: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerArm: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      rightLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftUpperLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] }),
      leftLowerLeg: reactive<AcuItemsType>({ id: '', ids: [], names: [], x: [], y: [] })
    }
  }
  const selectedDirection = ref<Direction>(Direction.front)
  const selectedBodyParts = ref<BodyParts>(BodyParts.head)
  const selectedRowId = ref<string>('')

  const { dataWrite } = inject(UseClinicKey) as UseClinicType
  const { acuItems: originalAcuItems } = inject(UseAcuItemSubscriptionKey) as UseAcuItemSubscriptionType

  const acuListTitles = [
    {
      name: '経穴名',
      width: 3
    },
    {
      name: '位置',
      width: 2
    },
    {
      name: '操作',
      width: 2
    }
  ]

  const initAcuItems = () => {
    Object.keys(originalAcuItems.value).forEach(dir => {
      Object.keys(originalAcuItems.value[dir]).forEach(body => {
        if (originalAcuItems.value[dir][body]) {
          acuItems[dir][body].id = originalAcuItems.value[dir][body]!.id
          acuItems[dir][body].ids = [...originalAcuItems.value[dir][body]!.parts.map(p => p.id)]
          acuItems[dir][body].names = [...originalAcuItems.value[dir][body]!.parts.map(p => p.name)]
          acuItems[dir][body].x = [...originalAcuItems.value[dir][body]!.parts.map(p => p.x || null)]
          acuItems[dir][body].y = [...originalAcuItems.value[dir][body]!.parts.map(p => p.y || null)]
        }
      })
    })
  }

  // registerAcuItemで確定したidをセットする
  const setIds = (items: CreateAcuItemInput[]) => {
    items.forEach(item => {
      acuItems[item.direction][item.bodyParts].id = item.id!
    })
  }

  watch(
    () => selectedDirection,
    () => {
      selectedRowId.value = ''
    },
    { deep: true }
  )

  watch(
    () => selectedBodyParts,
    () => {
      selectedRowId.value = ''
    },
    { deep: true }
  )

  const selectRow = (id: string) => {
    selectedRowId.value = id
  }

  const upAcuParts = (index: number) => {
    (Object.keys(acuItems[selectedDirection.value][selectedBodyParts.value]) as (keyof AcuItemsType)[]).forEach(attr => {
      const newItems = [...acuItems[selectedDirection.value][selectedBodyParts.value][attr]]
      const tmp = newItems[index - 1]
      newItems.splice(index - 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      acuItems[selectedDirection.value][selectedBodyParts.value][attr] = newItems as (string & string[] & (number|null)[])
    })
  }

  const downAcuParts = (index: number) => {
    (Object.keys(acuItems[selectedDirection.value][selectedBodyParts.value]) as (keyof AcuItemsType)[]).forEach(attr => {
      const newItems = [...acuItems[selectedDirection.value][selectedBodyParts.value][attr]]
      const tmp = newItems[index + 1]
      newItems.splice(index + 1, 1, newItems[index])
      newItems.splice(index, 1, tmp)
      acuItems[selectedDirection.value][selectedBodyParts.value][attr] = newItems as (string & string[] & (number|null)[])
    })
  }

  const addAcuItem = () => {
    const newId = uuid()
    acuItems[selectedDirection.value][selectedBodyParts.value].ids = [...acuItems[selectedDirection.value][selectedBodyParts.value].ids, newId]
    acuItems[selectedDirection.value][selectedBodyParts.value].names = [...acuItems[selectedDirection.value][selectedBodyParts.value].names, '']
    acuItems[selectedDirection.value][selectedBodyParts.value].x = [...acuItems[selectedDirection.value][selectedBodyParts.value].x, null]
    acuItems[selectedDirection.value][selectedBodyParts.value].y = [...acuItems[selectedDirection.value][selectedBodyParts.value].y, null]
    selectedRowId.value = newId
  }

  const deleteAcuItem = (index: number) => {
    acuItems[selectedDirection.value][selectedBodyParts.value].ids = acuItems[selectedDirection.value][selectedBodyParts.value].ids
      .filter((a, j) => j !== index)
    acuItems[selectedDirection.value][selectedBodyParts.value].names = acuItems[selectedDirection.value][selectedBodyParts.value].names
      .filter((a, j) => j !== index)
    acuItems[selectedDirection.value][selectedBodyParts.value].x = acuItems[selectedDirection.value][selectedBodyParts.value].x
      .filter((a, j) => j !== index)
    acuItems[selectedDirection.value][selectedBodyParts.value].y = acuItems[selectedDirection.value][selectedBodyParts.value].y
      .filter((a, j) => j !== index)
    selectedRowId.value = ''
  }

  const setAcuPosition = ({ x, y }: { x: number, y: number }) => {
    if (selectedRowId.value === '') {
      return
    }
    const index = acuItems[selectedDirection.value][selectedBodyParts.value].ids.findIndex(id => id === selectedRowId.value)
    if (index < 0) {
      return
    }
    acuItems[selectedDirection.value][selectedBodyParts.value].x[index] = x
    acuItems[selectedDirection.value][selectedBodyParts.value].y[index] = y
  }

  const acuPartsRows = computed(() => {
    return acuItems[selectedDirection.value][selectedBodyParts.value].ids.map((id, j) => {
      const x = acuItems[selectedDirection.value][selectedBodyParts.value].x[j]
      const y = acuItems[selectedDirection.value][selectedBodyParts.value].y[j]
      return {
        id,
        columns: [
          {
            value: acuItems[selectedDirection.value][selectedBodyParts.value].names[j],
            width: 3,
            readonly: !dataWrite.value,
            type: 'edit',
            emitType: 'name'
          },
          {
            value: x === null || y === null ? '?' : `${x},${y}`,
            width: 2,
            type: 'text'
          },
          {
            buttons: [
              {
                icon: 'arrow-up',
                state: 'normal',
                emitType: 'up',
                disabled: j === 0 || !dataWrite.value
              },
              {
                icon: 'arrow-down',
                state: 'normal',
                emitType: 'down',
                disabled: j === acuItems[selectedDirection.value][selectedBodyParts.value].ids.length - 1 || !dataWrite.value
              },
              {
                icon: 'times',
                state: 'delete',
                emitType: 'delete',
                disabled: !dataWrite.value
              }
            ],
            width: 2,
            type: 'iconButtons'
          }
        ]
      }
    })
  })

  const addedAcuItems = computed<CreateAcuItemInput[]>(() => {
    return Object.keys(acuItems).flatMap(dir => {
      return Object.keys(acuItems[dir])
        .filter(body => acuItems[dir][body].id === '' && acuItems[dir][body].ids.length > 0)
        .map(body => {
          const parts: AcuPartsInput[] = acuItems[dir][body].ids.map((id, j) => {
            return {
              id,
              x: acuItems[dir][body].x[j] || undefined,
              y: acuItems[dir][body].y[j] || undefined,
              name: acuItems[dir][body].names[j]
            }
          })
          return {
            id: uuid(),
            direction: dir as Direction,
            bodyParts: body as BodyParts,
            parts
          }
        })
    })
  })

  const updatedAcuItems = computed<UpdateAcuItemInput[]>(() => {
    return Object.keys(acuItems).flatMap(dir => {
      return Object.keys(acuItems[dir])
        .filter(body => acuItems[dir][body].id !== '')
        .filter(body => {
          const org = originalAcuItems.value[dir][body]
          if (!org) {
            return false
          }
          return acuItems[dir][body].ids.length !== org.parts.length ||
            org.parts.some((p, j) => {
              return acuItems[dir][body].ids[j] !== p.id ||
                acuItems[dir][body].x[j] !== p.x ||
                acuItems[dir][body].y[j] !== p.y ||
                acuItems[dir][body].names[j] !== p.name
            })
        })
        .map(body => {
          const parts: AcuPartsInput[] = acuItems[dir][body].ids.map((id, j) => {
            return {
              id,
              x: acuItems[dir][body].x[j] || undefined,
              y: acuItems[dir][body].y[j] || undefined,
              name: acuItems[dir][body].names[j]
            }
          })
          return {
            id: acuItems[dir][body].id,
            direction: dir as Direction,
            bodyParts: body as BodyParts,
            parts
          }
        })
    })
  })

  const acuList = computed<AcuPartsSelection[]>(() => {
    return acuItems[selectedDirection.value][selectedBodyParts.value].ids.map((id, j) => {
      return {
        id,
        x: acuItems[selectedDirection.value][selectedBodyParts.value].x[j] || undefined,
        y: acuItems[selectedDirection.value][selectedBodyParts.value].y[j] || undefined,
        name: acuItems[selectedDirection.value][selectedBodyParts.value].names[j],
        selected: id === selectedRowId.value,
        deprecated: false
      }
    })
  })

  const editing = computed(() => {
    return addedAcuItems.value.length > 0 ||
      updatedAcuItems.value.length > 0
  })

  const existsPartsList = computed(() => {
    const exists: {[key:string]: string[]} = {}
    Object.keys(acuItems).map(dir => {
      exists[dir] = Object.keys(acuItems[dir])
        .filter(body => acuItems[dir][body].ids.length > 0)
        .map(body => body)
    })
    return exists
  })

  // 全ての向き、部位に経穴の設定があるか
  const allSet = computed(() => {
    return Object.keys(acuItems).every(dir => {
      return Object.keys(acuItems[dir]).every(body => acuItems[dir][body].ids.length > 0)
    })
  })

  const _toRefs = () => {
    const _refs: AcuItemRefType = {}
    Object.keys(acuItems).forEach(dir => {
      _refs[dir] = {}
      Object.keys(acuItems[dir]).forEach(body => {
        _refs[dir][body] = { ...toRefs(acuItems[dir][body]) }
      })
    })
    return _refs
  }

  return {
    acuItems: _toRefs(),
    selectedDirection,
    selectedBodyParts,
    selectedRowId,
    acuListTitles,
    initAcuItems,
    setIds,
    selectRow,
    upAcuParts,
    downAcuParts,
    addAcuItem,
    deleteAcuItem,
    setAcuPosition,
    acuPartsRows,
    acuList,
    addedAcuItems,
    updatedAcuItems,
    editing,
    existsPartsList,
    allSet
  }
}

export type UseAcuItemType = ReturnType<typeof useAcuItem>
export const UseAcuItemKey: InjectionKey<UseAcuItemType> = Symbol('AcuItem')
