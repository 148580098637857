<template>
  <div class="toggle-group-frame" :class="$mq">
    <toggle-switch
      v-for="(toggle, index) in toggles"
      :key="'toggle-group-'+groupId+'-'+index"
      :modelValue="modelValue.includes(toggle.value)"
      :disabled="readonly"
      @update:modelValue="onClick(toggle.value, $event)">{{ toggle.name }}
    </toggle-switch>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/atoms/toggleSwitch.vue'

export default {
  name: 'multi-choice-group',
  components: {
    ToggleSwitch
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    groupId: {
      type: String,
      required: true
    },
    toggles: {
      type: Array,
      required: true
    },
    modelValue: Array,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick (value, isSet) {
      if (isSet) {
        this.$emit('update:modelValue', [...this.modelValue, value])
      } else {
        this.$emit('update:modelValue', [...this.modelValue.filter(v => v !== value)])
      }
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.toggle-group-frame {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  width: 85%;
  margin: 0 auto;

  &.mobile, &.tablet, &.laptop {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
