import {
  createRouter,
  createWebHistory,
  RouteRecordRaw
} from 'vue-router'
import {
  guardLogin,
  guardDashboard,
  guardWorkSpace,
  guardPatientWrite,
  guardPatientRead,
  guardCalendarWrite,
  guardCalendarRead,
  guardAcceptAppointment,
  guardDataWrite,
  guardAccountWrite,
  guardClinicOwner
} from '@/router/guard'

import Login from '@/views/authentication/Login.vue'
import SignUp from '@/views/authentication/SignUp.vue'
import ConfirmSignUp from '@/views/authentication/ConfirmSignUp.vue'
import PasswordReminder from '@/views/authentication/PasswordReminder.vue'
import ConfirmRemind from '@/views/authentication/ConfirmRemind.vue'

import AccountSetting from '@/views/root/accountSetting/AccountSetting.vue'
import Dashboard from '@/views/root/Dashboard.vue'
import RegisterClinic from '@/views/root/RegisterClinic.vue'
import LoginClinic from '@/views/root/loginClinic/LoginClinic.vue'

import ClinicDashboard from '@/views/ClinicDashboard.vue'

import PatientDashboard from '@/views/patient/PatientDashboard.vue'
import RegisterPatient from '@/views/patient/register/RegisterPatient.vue'
import TemporaryPatientList from '@/views/patient/temporaryPatientList/TemporaryPatientList.vue'
import BulkRegisterPatient from '@/views/patient/bulk/BulkRegisterPatient.vue'
import RegisterPatientQR from '@/views/patient/qr/RegisterPatientQR.vue'
import PatientAnswerList from '@/views/patient/answer/PatientAnswer.vue'
import PatientList from '@/views/patient/list/Patient.vue'

import Appointment from '@/views/appointment/calender/Appointment.vue'
import RegisterAppointment from '@/views/appointment/register/RegisterAppointment.vue'
import UpdateAppointment from '@/views/appointment/update/UpdateAppointment.vue'

import BusinessHour from '@/views/businessHour/calendar/BusinessHour.vue'
import RegisterBusinessHour from '@/views/businessHour/register/RegisterBusinessHour.vue'

import KarteMenu from '@/views/karte/KarteMenu.vue'
import KarteByAppointment from '@/views/karte/KarteByAppointment/KarteByAppointment.vue'
import KarteSelectAppointment from '@/views/karte/KarteByAppointment/KarteSelectAppointment.vue'
import KarteByPatientList from '@/views/karte/KarteByPatientList/KarteByPatientList.vue'
import KarteList from '@/views/karte/KarteList/KarteList.vue'
import Karte from '@/views/karte/Karte/Karte.vue'

import KarteDataMenu from '@/views/karteData/KarteDataMenu.vue'
import KarteDataItem from '@/views/karteData/item/KarteDataItem.vue'
import KarteDataAcu from '@/views/karteData/acu/KarteDataAcu.vue'
import KarteTemplate from '@/views/karteData/template/KarteTemplate.vue'
import InquiryItem from '@/views/karteData/inquiry/InquiryItem.vue'

import Account from '@/views/account/list/Account.vue'
import ClinicAccountSetting from '@/views/account/clinicAccountSetting/ClinicAccountSetting.vue'
import InviteAccount from '@/views/account/inviteAccount/inviteAccount.vue'

import Setting from '@/views/setting/Setting.vue'

import Timeout from '@/views/Timeout.vue'
import { AppName } from '@/config'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    component: Login,
    meta: { title: 'ログイン' },
    beforeEnter: guardLogin
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'ログイン' },
    beforeEnter: guardLogin
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: { title: 'アカウント登録' },
    beforeEnter: guardLogin
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: ConfirmSignUp,
    meta: { title: 'アカウント登録' },
    beforeEnter: guardLogin
  },
  {
    path: '/reminder',
    name: 'reminder',
    component: PasswordReminder,
    meta: { title: 'パスワードリマインド' },
    beforeEnter: guardLogin
  },
  {
    path: '/confirmRemind',
    name: 'confirmRemind',
    component: ConfirmRemind,
    meta: { title: 'パスワードリマインド' },
    beforeEnter: guardLogin
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { title: 'ホーム' },
    beforeEnter: guardDashboard
  },
  {
    path: '/register_clinic',
    name: 'register_clinic',
    component: RegisterClinic,
    meta: { title: '施設登録' },
    beforeEnter: guardDashboard
  },
  {
    path: '/signin_clinic',
    name: 'signin_clinic',
    component: LoginClinic,
    meta: { title: '施設にログイン' },
    beforeEnter: guardDashboard
  },
  {
    path: '/account',
    name: 'account',
    component: AccountSetting,
    meta: { title: 'アカウント' },
    beforeEnter: guardDashboard
  },
  {
    path: '/w/:clinicId',
    name: 'workSpace',
    component: ClinicDashboard,
    meta: { title: 'ワークスペース' },
    beforeEnter: guardWorkSpace
  },
  {
    path: '/w/:clinicId/patient',
    name: 'patient',
    component: PatientDashboard,
    meta: { title: '患者メニュー' },
    beforeEnter: guardPatientWrite
  },
  {
    path: '/w/:clinicId/register_patient',
    name: 'registerPatient',
    component: RegisterPatient,
    meta: { title: '患者登録' },
    beforeEnter: guardPatientWrite
  },
  {
    path: '/w/:clinicId/temporary_patient_list',
    name: 'temporary_patient_list',
    component: TemporaryPatientList,
    meta: { title: '仮患者様本登録' },
    beforeEnter: guardPatientWrite
  },
  {
    path: '/w/:clinicId/bulk_register_patient',
    name: 'bulk_register_patient',
    component: BulkRegisterPatient,
    meta: { title: '一括登録' },
    beforeEnter: guardPatientWrite
  },
  {
    path: '/w/:clinicId/patient_answer',
    name: 'patientAnswerList',
    component: PatientAnswerList,
    meta: { title: '患者着信一覧' },
    beforeEnter: guardPatientWrite
  },
  {
    path: '/w/:clinicId/register_patient_qr',
    name: 'registerPatientQR',
    component: RegisterPatientQR,
    meta: { title: '患者登録QR' },
    beforeEnter: guardPatientWrite
  },
  {
    path: '/w/:clinicId/patient_list',
    name: 'patientList',
    component: PatientList,
    meta: { title: '患者一覧' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/appointment',
    name: 'appointment',
    component: Appointment,
    meta: { title: '予約' },
    beforeEnter: guardCalendarRead
  },
  {
    path: '/w/:clinicId/register_appointment',
    name: 'register_appointment',
    component: RegisterAppointment,
    meta: { title: '予約登録' },
    beforeEnter: guardCalendarWrite
  },
  {
    path: '/w/:clinicId/update_appointment',
    name: 'update_appointment',
    component: UpdateAppointment,
    meta: { title: '予約変更' },
    beforeEnter: guardCalendarWrite
  },
  {
    path: '/w/:clinicId/business_hour',
    name: 'business_hour',
    component: BusinessHour,
    meta: { title: '営業時間編集' },
    beforeEnter: guardAcceptAppointment
  },
  {
    path: '/w/:clinicId/register_business_hour',
    name: 'register_business_hour',
    component: RegisterBusinessHour,
    meta: { title: '営業時間編集' },
    beforeEnter: guardAcceptAppointment
  },
  {
    path: '/w/:clinicId/karte_menu',
    name: 'karte_menu',
    component: KarteMenu,
    meta: { title: 'カルテ' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/karte_by_appointment',
    name: 'karte_by_appointment',
    component: KarteByAppointment,
    meta: { title: 'カルテ' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/karte_select_appointment',
    name: 'karte_select_appointment',
    component: KarteSelectAppointment,
    meta: { title: 'カルテ' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/karte_by_patient_list',
    name: 'karte_by_patient_list',
    component: KarteByPatientList,
    meta: { title: 'カルテ' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/karte_list',
    name: 'karte_list',
    component: KarteList,
    meta: { title: 'カルテ' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/karte',
    name: 'karte',
    component: Karte,
    meta: { title: 'カルテ' },
    beforeEnter: guardPatientRead
  },
  {
    path: '/w/:clinicId/karte_data_menu',
    name: 'karte_data_menu',
    component: KarteDataMenu,
    meta: { title: 'カルテデータ編集' },
    beforeEnter: guardDataWrite
  },
  {
    path: '/w/:clinicId/karte_data_item',
    name: 'karte_date_item',
    component: KarteDataItem,
    meta: { title: 'カルテ項目編集' },
    beforeEnter: guardDataWrite
  },
  {
    path: '/w/:clinicId/karte_data_acu',
    name: 'karte_date_acu',
    component: KarteDataAcu,
    meta: { title: '経穴リスト編集' },
    beforeEnter: guardDataWrite
  },
  {
    path: '/w/:clinicId/karte_template',
    name: 'karte_template',
    component: KarteTemplate,
    meta: { title: 'カルテテンプレート編集' },
    beforeEnter: guardDataWrite
  },
  {
    path: '/w/:clinicId/inquiry_item',
    name: 'inquiry_item',
    component: InquiryItem,
    meta: { title: '問診編集' },
    beforeEnter: guardDataWrite
  },
  {
    path: '/w/:clinicId/account_list',
    name: 'account_list',
    component: Account,
    meta: { title: 'アカウント一覧' },
    beforeEnter: guardAccountWrite
  },
  {
    path: '/w/:clinicId/clinic_account_setting/:id',
    name: 'clinic_account_setting',
    component: ClinicAccountSetting,
    meta: { title: 'アカウント設定' },
    beforeEnter: guardAccountWrite
  },
  {
    path: '/w/:clinicId/invite_account',
    name: 'invite_account',
    component: InviteAccount,
    meta: { title: 'アカウント招待' },
    beforeEnter: guardClinicOwner
  },
  {
    path: '/w/:clinicId/setting',
    name: 'setting',
    component: Setting,
    meta: { title: '設定' },
    beforeEnter: guardWorkSpace
  },
  {
    path: '/timeout',
    name: 'timeout',
    component: Timeout,
    meta: { title: '' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = `${AppName} - ${to.meta.title}`
  }
})

export default router
