import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import '@/registerServiceWorker'

// directive
import { focusDirective } from '@/directive/vueFocus'
import { enterDirective } from '@/directive/vueEnter'

// mq
import VueMq from 'vue3-mq'
import { mqConfig } from '@/config'
import 'animate.css/animate.compat.css'

// amplify
import awsmobile from '@/aws-exports'
import Amplify, { Auth } from 'aws-amplify'
import PubSub from '@aws-amplify/pubsub'

// font awesome
import '@/plugins/fontAwesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

if (window.location.pathname === '/index.html') {
  window.location.href = '/'
}

// appsyncでcognitoのcustom attributeを使用するには
// 1) cognitoのアプリクライアント設定で属性を読み取り可能にする
// 2) appsyncのアクセスにはID Tokenを使う (以下の様にconfigureでAPIを設定する)
//
// [以下参照]
// https://forums.aws.amazon.com/thread.jspa?threadID=302130
// https://takumon.com/how-to-get-user-custom-attributes-in-resolver-with-amplify-appsync-cognito

Amplify.configure({
  ...awsmobile,
  API: {
    graphql_headers: async () => {
      try {
        const session = await Auth.currentSession()
        const token = session.getIdToken().getJwtToken()
        return { Authorization: token }
      } catch (e) {
        console.error(e)
        return {}
      }
    }
  }
})
PubSub.configure({ ...awsmobile })

createApp(App)
  .use(router)
  .use(VueMq, mqConfig)
  .directive('focus', focusDirective)
  .directive('enter', enterDirective)
  .component('fa-icon', FontAwesomeIcon)
  .mount('#app')
